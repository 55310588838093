

<Popup bind:this={drinkPopup} opened={open} class="drink-detail-popup drink-detail-standalone" on:popupOpened={() => popupOpened=true} tabletFullscreen on:popupClosed={endPopup}>
    <div class="drink-popup-viewer">
      <View>
       <div class="page drink-popup-viewer-page no-toolbar" data-name="drink-popup-viewer-page">
     {#if drink}
     <Navbar transparent>
      <NavLeft>
       <a href="#1" class="link  sheet-close popup-close color-white">
          <i class="icon icon-back"></i>
        </a>
      </NavLeft>
     </Navbar>
    
      {#if deviceWidth < 481}
        <CardImg cornerRadius="8px" height="300px" content={false} bold bottomHeadingTitle={drinkTitle} fadeOut={false} blurText imgUrl={drink.picUrl} on:cardClicked={(e)=> {}}>
        </CardImg>
      {:else}
         <CardImg cornerRadius="8px" height="600px" content={false} bold bottomHeadingTitle={drinkTitle} fadeOut={false} blurText imgUrl={drink.picUrl} on:cardClicked={(e)=> {}}>
        </CardImg>
      {/if}
      {#if drinkDescr}
      <Block>
        <p><em>{@html drinkDescr}</em></p>
      </Block>
      {/if}
     
      {#if drink.ingred}
        <BlockTitle>Ingredients</BlockTitle>
         <Block>
            <Swiper class="swiper-ingred" init={false}>
            <div slot="before-wrapper"><em><small>NOTE: Drink recipe and ingredients may vary by restaurant. Click on any ingredient to customize.</small></em></div>
            {#each drink.ingred as ig,index}
              <SwiperSlide>
                    <IFeature w="50px" h="50px" title={ig} id={index} pic={'https://img.imageboss.me/ing/width/50/' + ig + '-Small.png'} lazy on:featureClicked={(e)=> f7.sheet.open(".sheet-action")}></IFeature>
              </SwiperSlide>
             {/each}
            </Swiper>
          </Block>
      {/if}

      <Block>
        <Button color="green" fill on:click={openOrderCustomize}>Order drink</Button>
        <Button color="blue" popupClose>Back to menu</Button>
      </Block>
      <Sheet backdrop class="sheet-confirm" position="top" style="height:auto;">
        <Toolbar>
          <div class="left"></div>
          <div class="right">
            <Link sheetClose on:click={()=> {}}>Cancel</Link>
          </div>
        </Toolbar>
        <PageContent>
          <Block style="margin-top:10px">
              <Row>
                <Col width="33"><img src="https://img.imageboss.me/justdrinks/width/125/man-bar.png" alt="" width="125"/></Col>
                <Col width="66"><p>Hey {$user.displayName}! You want a {@html drinkConfirmStr}. 
                Is this correct?</Col>
              </Row>
              <Button small color="green" fill sheetClose on:click={completeOrder}>Confirm</Button>
               <small><em>Note: Actual drink may vary from photo. Ingredients may vary depending on location. Non-well alcohol may have a higher price. Please check with your server for more details.</em></small>
            </Block>
         </PageContent>
      </Sheet>
      <Sheet class="sheet-action" on:sheetClose={(e)=>{ f7.$('.sheet-action').removeClass('expand'); expanded=false;}} closeByOutsideClick>
          <View name="sheet-modal-view" class="brand-select-view">
           <div class="page" data-name="drink-sheet-view">
               {#if !isBeer && !isWine}
                    <div class="sheet-modal-swipe-step">
                          <Block style="margin-top:5px">
                          <small>Customize</small>
                          {#if drink.ingred}
                            <List style>
                              {#if curAlcohol}
                                <ListItem title={curAlcoholLabel} smartSelect smartSelectParams={{searchbar: true, closeOnSelect: true,searchbarPlaceholder: 'Search ' + curAlcoholLabel}}>
                                  <select name={curAlcoholId} bind:value={selectedAlc}>
                                    <option value="well">Well</option>
                                    {#each curAlcohol as a,index}
                                      <option value={index}>{a.name}</option>
                                    {/each}
                                  </select>
                                </ListItem>
                              {/if}
                              {#if drink.customizeMixer}
                                  <ListItem title="Mixer" class="customizeMixer"  smartSelect   smartSelectParams={{searchbar: true,searchbarPlaceholder:'Search mixer', closeOnSelect: true,on: { closed: closedCustomizeMixer }}}> 
                                    <select name="mixer" id="selMixer"  bind:value={selectedMixer}>
                                      <option value="No mixer">No mixer</option>
                                      {#each popMixers as p,index}
                                        <option value={p}>{p}</option>
                                      {/each}
                                    </select>
                                  </ListItem>
                              {/if}
                              <ListItem title="Prepared"  smartSelect smartSelectParams={{closeOnSelect: true,on: { closed: closedPrepared } }}> 
                                <select name="prepare" id="selPrepared"  bind:value={selectedPrep} >
                                  {#each prepareMethods as p,index}
                                    <option value={index}>{p}</option>
                                  {/each}
                                </select>
                              </ListItem>
                              {#if drinkAlcoholInfo}
                                <ListItem title="Salt">
                                  <div slot="after">
                                  <Stepper fill value="1" max="1"></Stepper>
                                  </div>
                                </ListItem>
                              {/if}
                            </List>
                          {/if}
                          {#if $disableOrdering == false}
                            <Button sheetClose fill large color="default" on:click={orderDrink(drink.id)}>Order Drink</Button>
                          {/if}
                          </Block>
                        </div>
                {:else}
                        <Block>
                         {#if $disableOrdering == false}
                         <Button fill large color="default" on:click={orderDrink(drink.id)}>Order Drink</Button>
                         {/if}
                        </Block>
                {/if}
              </div>
            </View>
          </Sheet>
      {/if}
      </div>
      </View>
    </div>
    
</Popup>

<script>
import {place,venueAlcoholList,userInfo,disableOrdering,venueOrder,alcoholTypes} from '../js/store'
import BackBlur from './dts-shared-components/ui/back-blur-text.svelte'

export let open=false;
export let drink=null;
export let pic="";
let popupOpened=false;
export let name;
export let zoom=false;
export let subtitle='';

import CardImg from '../components/dts-shared-components/card-img-back.svelte'

let expandAction=false;
let expanded=false;
let bindCustomizeMixer;
let isBeer=false,isWine=false;

let deviceWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
let mobileWidth=500;
if (deviceWidth > 481) mobileWidth=700;



function opened(){

}

import { tick,createEventDispatcher} from 'svelte'
import { f7,f7ready, View,Page,Link, Popup,NavRight,NavTitle,NavLeft,List,Stepper,ListItem,Sheet,Button,Swiper,Toolbar,PageContent,CardFooter,Row,Col,SwiperSlide,Navbar, BlockTitle, Block,Card,CardHeader,CardContent,SkeletonBlock,Chip } from 'framework7-svelte';
 import IFeature from './dts-shared-components/img-feature.svelte'
import HeaderBack from './dts-shared-components/ui/header-back-img.svelte'
import {user,openPopup} from './dts-shared-components/auth/store'
let curAlcohol;
let curAlcoholLabel;
let curAlcoholId;
let curSelAlcohol='';
let selectedAlc="well";
let drinkConfirmStr="";
let selectedPrep="0";
let selectedMixer="";
let selectedPrepName="";
let ingredCustomize=[];
let selMethodBox;
let prepareMethods=['Standard','Chilled','Blended','On the rocks','Neat'];

let popMixers=['Club soda','Coca-Cola','Cranberry juice','Diet coke','Ginger ale','Ginger beer','Lemon juice','Lemonade','Lemon-lime soda','Orange juice','Pineapple juice','Red bull','Soda water','Tonic water','Vermouth']

let swiperInstance;
let drinkPopup=null;

let alcoholTypeList=$alcoholTypes;

let drinkAlcoholInfo=null;

$: if (drink && popupOpened){
    initPopup();
   //expanded=true;
}

const dispatch = createEventDispatcher();

function initializeSwiper(){
  tick().then(()=>{
 swiperInstance=f7.swiper.create('.swiper-ingred', {
    speed: 500,slidesPerView: '4',spaceBetween: 8,slideToClickedSlide:true
  });
  })
}
let sheet;

function initAlcoholInfo(){
  drinkAlcoholInfo = {
    count: 0,
    alcohols:[],
    nonAlcohols:[],
    isCustomizeMixer: false,
    hasSalt: false
  }
  let count=0;
  drink.ingred.forEach((it)=>{
    var item=it;
    var litem=item.toLowerCase();
    if (litem == 'light rum' || litem == 'dark rum') {
      item = "Rum";
       drinkAlcoholInfo.alcohols.push(item);
    }else if (litem == 'salt') {
      drinkAlcoholInfo.hasSalt=true
      drinkAlcoholInfo.nonAlcohols.push(item)
    }else{
        let found=alcoholTypeList.findIndex((a)=>{
          return a == item;
        });
        if (found > -1){
            drinkAlcoholInfo.alcohols.push(item);
            count++;
        }else{
          drinkAlcoholInfo.nonAlcohols.push(item);
        }
    }
  });
  // Redo ingred list with all alcohol first.
  let ingred=[];
   drinkAlcoholInfo.alcohols.forEach((a)=>{
     ingred.push(a);
   })
    drinkAlcoholInfo.nonAlcohols.forEach((a)=>{
     ingred.push(a);
   })
  drinkAlcoholInfo.count=count;
  drink.ingred=ingred;

  if (drinkAlcoholInfo.alcohols.length == 1){
    drinkAlcoholInfo.isCustomizeMixer=true;
  }
  console.log("drink info",drinkAlcoholInfo,drink)

}

let drinkPic="",drinkTitle="",drinkSubtitle="",drinkDescr="";
function initPopup(){
  selectedAlc="well"
   isWine=false;
   isBeer=false;

    curAlcohol=null;
      curAlcoholId=null;
      curAlcoholLabel=null;
  if (drink.internal)
    drinkPic=`https://img.imageboss.me/justdrinks/width/${mobileWidth}/${drink.pic}`
  else
    drinkPic=`https://img.imageboss.me/cocktails/width/${mobileWidth}/${drink.pic}`;


   if (drink.type && drink.type.includes("beer")) isBeer=true;
   else 
    if (drink.type && drink.type.includes("wine")) isWine=true;

  console.log("before",drink.ingred)
  if (!isWine && !isBeer){
     initAlcoholInfo();
     if (drink.customizeMixer){
        if (drink.defaultMixer)
          selectedMixer=drink.defaultMixer;
     } 

     initializeSwiper();
     //sheet=f7.sheet.open(".sheet-action")
      
     if (drinkAlcoholInfo.alcohols.length > 0)
         setCurrentAlcohol(drinkAlcoholInfo.alcohols[0])
  }
  
  if (isWine){
    drinkTitle=`${drink.name} ${drink.style}`
    drinkSubtitle=`${drink.maker} &middot; ${drink.year} &middot; ${drink.from}`;
  }else{
    drinkTitle=drink.name;
    drinkSubtitle="";
  }
  if (drink.descr)
    drinkDescr=drink.descr;
  else
    drinkDescr="";

console.log("--def mixer",selectedMixer)
  

}


function closedPrepared(){
  let curPrep=prepareMethods[selectedPrep];
  if (curPrep == 'On the rocks' || curPrep == 'Blended'){ // On the rocks
    if (drink.ingred.length == 2){
      // be cute and add ice ingredient
      drink.ingred=[...drink.ingred,'Ice'];
    }
  }else{
    if (drink.ingred[2] == 'Ice'){
       drink.ingred.splice(2,1);
       drink.ingred=drink.ingred;
    }
  }
}
function closedCustomizeMixer(){
  // Update mixer ingred pic
  if (selectedMixer !== 'No mixer'){
    if (drink.ingred.length > 1){
      drink.ingred[1]=selectedMixer
    }else{
        drink.ingred=[...drink.ingred,selectedMixer];
    }
  }
  else{
    if (drink.ingred[1].length > 1){
       drink.ingred.splice(1,1)
      drink.ingred=drink.ingred;
    }
     
  }
}
function endPopup(){
  f7.$('.sheet-action').removeClass('expand')
  f7.swiper.destroy('.swiper-ingred')
  f7.sheet.close(".sheet-action")
  dispatch('close',{})
  open=false;
  popupOpened=false;

}
function findFirstAlcohol(){
  let found=-1;
  let ingreds=drink.ingred;
  let arr=["tequila","vodka","rum","whisky","gin","scotch","bourbon"];

    return arr.findIndex(function (v) {
        return ingreds.indexOf(v);
    });

}
function setCurrentAlcohol(alcohol){
   let venueAlc=$venueAlcoholList;
    let idl=alcohol.toLowerCase();
    if (venueAlc[idl]){
      curAlcohol=venueAlc[idl].list;
      curAlcoholId=idl;
      curAlcoholLabel=alcohol;
    }else{
      curAlcohol=[];
      curAlcoholLabel=alcohol;
      curAlcoholId=idl;
    }
}
function clickIngredient(){
  /*
  let venueAlc=$venueAlcoholList;
  let alcindex=findFirstAlcohol();
  if (alcindex > -1){
      let ingred=drink.ingred[alcindex];
      if (ingred.includes("rum")) ingred="Rum"; // handle "light/dark rum"

      if (ingred){
        let idl=ingred.toLowerCase();
          if (venueAlc[idl]){
            curAlcohol=venueAlc[idl].list;
            curAlcoholId=idl;
            curAlcoholLabel=ingred;
          }else{
            curAlcohol=[];
            curAlcoholLabel=ingred;
              curAlcoholId=idl;
          }
      }
  }else{
    curAlcohol=[];

  }
  */
  //swiperInstance.slideTo(swiperInstance.clickedIndex,500)
  //swiperInstance.activeIndex=swiperInstance.clickedIndex;
 selectedMixer=selectedMixer;
 
  f7.$('.sheet-action').addClass('expand')
  expanded=true;

}
function completeOrder(){
  let obj = {
    drink,
    order: drinkConfirmStr
  };
  //f7.popup.close();
  open=false;

   dispatch('completeOrder',obj);
   
}

function openOrderCustomize(){
    if (!isBeer && !isWine)
      f7.sheet.open('.sheet-action');
    else{
      // open confirmation
       orderDrink(drink.id)
    }
}

function orderDrink(id){
    if (!$venueOrder){
         f7.dialog.confirm("You must scan a QR code on the table to order. Would you like to scan now?","Table scan required.",
          function () {
              // OK.
              //f7.popup.close();
              open=false;
              if (!f7.device.desktop)
                f7.views.current.router.navigate('/qrscan/');
              else
              	f7.views.current.router.back();

          },function () {

          });
         return;
    }
    /*
     if ($user.isAnonymous){
       f7.dialog.confirm("We need to complete your account setup before ordering (No credit card required)","Setup required",
       function () {
          // OK.
           f7.popup.close();
          openPopup.set(true);

       },function () {

       });
       return;
     }
    */
      if (!isBeer && !isWine){
        if (curAlcohol)
          if (selectedAlc == "well")
            curSelAlcohol="well"
          else{
              curSelAlcohol=curAlcohol[selectedAlc].name;
          }

         selectedPrepName=prepareMethods[selectedPrep];
         drinkConfirmStr=`<b>${drink.name}</b>`;
         
         if (curAlcohol)
           drinkConfirmStr += ` made with ${curSelAlcohol} ${curAlcoholLabel} `;
         
         if (drink.customizeMixer){
           drinkConfirmStr += ` and ${selectedMixer} `
           drinkConfirmStr += `served ${selectedPrepName.toLowerCase()}.`
         }
      }else{
        if (isWine){
          drinkConfirmStr=`glass of ${drink.year} <b>${drink.name}</b> ${drink.style} from ${drink.from}.`
        }else
          drinkConfirmStr=`<b>${drink.name}</b>.`
      }
     
      f7.sheet.open('.sheet-confirm');
      expanded=false;
      f7.$('.sheet-action').removeClass('expand')

    
   
     //f7.dialog.confirm(`Request (1) ${drink.name} from ${$place.data.name}?`, function () {
       /*
      f7.dialog.preloader();
      window.setTimeout(()=>{
            f7.dialog.close();
            f7.dialog.alert('Request sent! Your drink should be delivered shortly. Please be patient.');
            f7.popup.close();
            window.setTimeout(()=>{
                  const res=	window.fetch(`https://us-central1-fiesta-drinks.cloudfunctions.net/sendNotif`,
                  {method:"POST",headers:{"Content-Type":"application/json"},
                  body:JSON.stringify({token: $messagingToken,title: "We've received your order!",msg:`We are preparing your ${drink.name}!`,pic: drink.pic,icon:`${drink.pic}/preview`, ext:true,id:drink.id})});
                  res.then((s)=>{
                  })
            },10000)
            
      },2000)

      });
      */

  

    /*
     app.dialog.create({
    title: drink.name,
    text: 'D',
    buttons: [
      {
        text: 'Button 1',
      },
      {
        text: 'Button 2',
      },
      {
        text: 'Button 3',
      },
    ],
    verticalButtons: true,
  }).open();
  */
}


</script>

<style>
:global(.sheet-action .list ul) {
  margin-top:30px;
}

h1 { color: #fff; margin:0; font-size: 2.1em }
.drink-popup-viewer {
  position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 400;
}
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) { 

}

.topc {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.drink-heading {
    position:absolute;
    top: 35px;
    left:16px;
    z-index:410;
    padding: 5px;
}
.drink-heading p,.drink-heading h4 {
  margin: 0px;
  color: gray;
 color: #fff;
   text-shadow: 2px 2px 4px #000000;
}
.drink-descr {
  position: relative;
  margin:10px;
  padding: 5px;
  text-shadow: 2px 2px 4px #000000;
}
:global(.brand-select-view .smart-select-page .list) {
  margin-top: 102px;
}
.drink-popup-viewer-page {
    background: none;
}
.zoom-container {
    width: 100%;
    height: 400px;

}
.zoomc {
    width: 100%;
    height: 100%;
    text-align: center;
}
.zoom-img > img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.zoomc > header {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 10px;
  background: inherit;
  background-attachment: fixed;
}

.zoomc > header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  background-attachment: fixed;
  -webkit-filter: blur(12px);
  filter: blur(12px);
}

.zoomc .footer {

}

.zoom-container img {
    max-width: 100%;
   min-height: 100%;
  min-width: 400px;
	
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
	
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

}



/*
.zoom-img::before {
  position: absolute;
  background: linear-gradient( rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%) !important;


  top:0px;
  height: 100px;
  width: 100%;
  content: '';
}
*/

:global(.theme-dark .zoom-container::before) {
  background: linear-gradient( rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%) !important;
}


:global(.drink-detail-standalone.modal-in) {
    transition-duration: 0ms;
    animation: photo-browser-in 600ms;
}


:global(.sheet-action.expand) {
  transition: height 500ms;
  height:450px
}
:global(.sheet-action) {
  background-color: #000000;
  opacity: 0.9;
  height:400px;
}
</style>