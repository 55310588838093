
<Icon material={icon} size="24">
{#if count > 0}
    <span class="badge color-red">{count}</span>
{/if}
</Icon> 


<script>
import {formatDistanceToNow,add} from 'date-fns'
import {notifications} from './store'
import { f7,f7ready,Link,Popover,Button,Popup,Page,List,ListItem,Navbar,NavRight,Block,Icon} from 'framework7-svelte';
import { onMount, onDestroy,tick } from 'svelte';
        import {messagingToken} from '../firebase/store.js'

export let color="default"
export let notifColor="red";
export let pageName=""; // set to page name bell is on
export let count=0;

let popupOpened=false;
let read=false;
export let icon;


let view="main";

let curNotifications=[];
let lastNotification;
let bell=true;
/*
$: 
{
    if ($notifications.length > 0) {
        curNotifications=$notifications;
        console.log("curNotifications",curNotifications)
        let curView=f7.views.current;
        let curPage=f7.views.current.router.currentRoute.name;

        if (curView.name == view && curPage == pageName && !popupOpened && !read){
            f7.popover.open(".notif-popover")
            read=true;
        }
    }
}
*/

let timeInterval=null;


onMount(() => {
    //view=f7.views.current.name;
   /*
    timeInterval=setInterval(()=>{
         const res=	window.fetch(`https://us-central1-fiesta-drinks.cloudfunctions.net/sendNotif`,
			{method:"POST",headers:{"Content-Type":"application/json"},
            body:JSON.stringify({token: $messagingToken,title: "Drink request confirmed!",msg:"We are preparing your Margarita!",pic:"https://www.thecocktaildb.com/images/media/drink/5noda61589575158.jpg",icon:"https://www.thecocktaildb.com/images/media/drink/5noda61589575158.jpg/icon", ext:true,id:"11007"})});
            res.then((s)=>{
                console.log("s",s)
            })

        //let list=$notifications;
        //let d=new Date();
        //list.push({msg: 'We have received your drink order!',time: d,pic:'https://www.thecocktaildb.com/images/media/drink/vrwquq1478252802.jpg/preview'});
        // const sorted = list.sort((a, b) => b.time - a.time);
        //notifications.set(sorted);
        //read=false;
        
    },60000) */

});

onDestroy(() => {
    console.log("bell destroy")
    bell=false;
    //window.clearInterval(timeInterval);
    
});


</script>