<script>
// tiny,small,medium,large
export let tiny=false;
export let small=false;
export let medium=false;
export let large=false;
export let imgUrl="";
export let id=0;
export let bottomHeadingTitle=null;
export let topHeadingTitle=null;
export let footer=false;
export let loading=false;
export let hide=false;
export let fadeOut=true;
export let headingSmall=false;
export let headingTextRight=false;
export let headingStrong=false;
export let content=true;
export let expandable=false;
export let height="auto";
export let cornerRadius="0px"
export let blurText=false;


let solid=!fadeOut;
//let solid=false;

let bottom=false;
let top=false;




import {css} from 'emotion/dist/emotion.umd.min.js';
import { createEventDispatcher,onMount } from 'svelte';
const dispatch=createEventDispatcher();


import {
    f7,
    f7ready,
    Card,CardContent,CardHeader,CardFooter,SkeletonBlock,
    Button,Link
  } from 'framework7-svelte';

  const  cardImgBack = css`
     cursor: pointer;
  `;
  const cardHeader=css`
  padding: 0;
  `;
onMount(() => {
if (bottomHeadingTitle != null) bottom=true;
if (topHeadingTitle != null) top=true;


});

function cardClick(id){

    dispatch('cardClicked',{id: id});
}

</script> 
 
 {#if !loading}
  {#if !hide}
    {#if !expandable}
      <Card  class={cardImgBack}>
          <CardHeader 
              class={cardHeader}
              valign="bottom"
              >
              <div class="img-container" style="--cheight:{height}; --cornerRadius:{cornerRadius}" class:tiny={tiny == true} class:small={small == true} class:medium={medium == true} class:large={large == true}>
                <img src={imgUrl} alt="" on:click={() => cardClick(id)}/>
                    {#if top || bottom}
                      <div class="heading" class:top={top} class:bottom class:fadeout={fadeOut} class:blur={blurText} class:solid={solid} class:tiny={headingSmall}>
                            {#if top}
                              <h3 class:right={headingTextRight} class:bold={headingStrong}>{topHeadingTitle}</h3>
                            {/if}
                            {#if bottom}
                              <h3 class:right={headingTextRight} class:bold={headingStrong}>{bottomHeadingTitle}</h3>
                            {/if}
                      </div>
                    {/if}
              </div>
          </CardHeader>
          {#if content}
            <CardContent><slot name="cardContent"></slot></CardContent>
          {/if}
          {#if footer}
            <slot name="cardFooter"></slot>
          {/if}
      </Card>
    {:else}
        <div class="card card-expandable">
          <!-- card content -->
          <div class="card-content">
            <div class="img-container">
                <img src={imgUrl} alt=""/>
                <CardHeader textColor="black" class="display-block">
                  <br/>
                  <small style="opacity: 0.7">eee</small>
                </CardHeader>
                <Link cardClose color="black" class="card-opened-fade-in" style="position: absolute; right: 15px; top: 15px" iconF7="multiply_circle_fill" />
              </div>
              <div class="card-content-padding">
              </div>
          </div>
        </div>
       
    {/if}
  {/if}
{:else}
  <Card class={cardImgBack + ' skeleton-text skeleton-effect-fade'}>
        <CardHeader class={cardHeader}>
          <div class="img-container" style="--cheight:{height}; --cornerRadius:{cornerRadius}" class:tiny={tiny== true} class:small={small == true} class:medium={medium == true} class:large={large == true}>
            <SkeletonBlock style="height:100%" />
          </div>
        </CardHeader>
        {#if content}
          <CardContent>Austin is great! I love him..Greateast accomplishment ever. dkflfksldfksldfksldfksdlfksldfksldfksldfksldfk
         </CardContent>
        {/if}
   </Card>
{/if}
<style>
.img-container {
  position: relative;
  overflow:hidden;
  height:var(--cheight);
   width:100%;
  /*width:200px;*/
  min-width:110px;
  padding: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
  border-radius: var(--cornerRadius);
}
.img-container img {
  position: absolute;
  display: block;
  object-fit: cover;
    object-position: middle;
    width: 100%;
    height: 100%;
    min-width:110px;
  top:0;
  left:0;
  right:0;
  bottom:0;
}
.img-container.small {
  height: 15vh;
}
.img-container.tiny {
  height: 10vh;
}
.img-container.medium {
  height: 30vh;
}
.img-container .heading {
  position: absolute;
  width:100%;
  left: 0px;
  color: #fff;
  padding-left: 12px;
  padding-top: 6px;
word-wrap: break-word;
  margin:0px;
  height: 2.2em;
  line-height: 1.5em;
}
.heading h3 {
 margin-top: 0;
}

.img-container .heading.blur:before {
  -webkit-filter: blur(4px);
  filter: blur(4px);
  content:'';
  background: inherit; 
}
.img-container .heading.blur:after {
  background: rgba(0, 0, 0, 0.25);
  content:''
}

.img-container .heading.fadeout {
  background: -webkit-linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
) !important; 
background: -moz-linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
)  !important;
background: -o-linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
)  !important;
background: linear-gradient( rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%) !important;
background: -ms-linear-gradient( rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%) !important;

}
.img-container .heading.top.fadeout {
  background: -webkit-linear-gradient(
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
) !important; 
background: -moz-linear-gradient(
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
)  !important;
background: -o-linear-gradient(
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
)  !important;
background: linear-gradient( rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%) !important;
background: -ms-linear-gradient( rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%) !important;

}
.heading.solid {
  background: rgba(0,0,0,0.75); 
}
.img-container .heading.tiny {
  font-size: 12px;
}
.heading.top {
  top:0px;
}
.heading.bottom {
  bottom: 0px;
}
.heading .right {
  float:right;
  padding-right: 10px;

}
.heading span.bold {
  font-style: italic;
  font-size:bold;
}
</style>
