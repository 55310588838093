<Page onPageInit={onPageInit} name="favs" noNavbar>
<BlockTitle medium>Favorites</BlockTitle>
<DrinkCards loading={false} drinks={favs} on:openDetails={detailsClick}></DrinkCards>
<DrinkPopup drink={curDrink} open={openDrinkPopup} on:close={()=> openDrinkPopup=false}></DrinkPopup>
</Page>
<script>
import {firestoreInstance} from '../components/dts-shared-components/firebase/store'
  import { f7,Page, Navbar, Block, BlockTitle,List,ListItem,ListInput,NavRight,Link } from 'framework7-svelte';
 import {user} from '../components/dts-shared-components/auth/store'
import {appInitialized} from '../js/store'
import DrinkCards from '../components/drinks-card.svelte'
 import DrinkPopup from '../components/popup-drink.svelte'
let favSnapshotUnsubscribe;
let favs=[];
let loading=false;
let curDrink;
let openDrinkPopup=false;

function detailsClick(e){
let d=e.detail.drink;
     curDrink=d;
    //f7.popup.open(".drink-detail-popup");
    openDrinkPopup=true;
}
$: if ($appInitialized){
    if (favSnapshotUnsubscribe)
        favSnapshotUnsubscribe();
        console.log(`drinkFavs/${$user.uid}/favs`)
     favSnapshotUnsubscribe=$firestoreInstance.collection(`drinkFavs/${$user.uid}/favs`)
      .onSnapshot(async function(qs) {
            let tmp=[];
            qs.forEach(function(doc) {
               let data=doc.data();
               tmp.push(data);
            });
            favs=tmp;
            
      });
}

function onPageInit(){
}
</script>