<Page name="locCheck" class="stretch-content" noToolbar noNavbar>
     <Loadable loader={() => import('../components/dts-shared-components/location/prompt.svelte')} let:component>
       <svelte:component this="{component}" heading={`${f7.name} needs your location to help you find, and order drinks.`} logo={f7.data.logo} />
     </Loadable>
</Page>
<script>
import Loadable from 'svelte-loadable'
import { onMount } from 'svelte';
import { f7,f7ready,Page, Navbar, Block,Button,Icon,Toolbar,Link,Swiper,SwiperSlide,Row,Col } from 'framework7-svelte';
import {gotPosition} from '../components/dts-shared-components/location/store'
onMount(() => {
    f7ready(() => {
    });
})
</script> 
