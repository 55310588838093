<Page name="alcohol"  on:pageInit={onPageInit}>
  <Navbar title="Alcohol List" backLink="Back" />
 <BlockTitle>Brands carried at {$place.data.name}</BlockTitle>

  {#if !loading}
  <BlockTitle>Tequila</BlockTitle>
  <Block strong inset class="alcohol-list">
  <img src={'https://www.thecocktaildb.com/images/ingredients/tequila-small.png'} width="100" class="lazy lazy-fade-in" alt=""/>
    <p>
      {#if brands.tequila}
      {#each brands.tequila.list as t}
      {t.name},
      {/each}
      {:else}
        {notFound}
      {/if}
    </p>
</Block>
<BlockTitle>Vodka</BlockTitle>
<Block strong inset class="alcohol-list">
  <img src={'https://www.thecocktaildb.com/images/ingredients/vodka-small.png'} width="100" class="lazy lazy-fade-in" alt=""/>
    <p>
    {#if brands.vodka}
      {#each brands.vodka.list as t}
      {t.name},
      {/each}
    {:else}
      {notFound}
    {/if}
    </p>
</Block>
<BlockTitle>Rum</BlockTitle>
<Block strong inset class="alcohol-list">
  <img src={'https://www.thecocktaildb.com/images/ingredients/rum-small.png'} width="100" class="lazy lazy-fade-in" alt="Rum"/>
    <p>
    {#if brands.rum}
      {#each brands.rum.list as t}
      {t.name},
      {/each}
    {:else}
      {notFound}
    {/if}
    </p>
</Block>
<BlockTitle>Gin</BlockTitle>
<Block strong inset class="alcohol-list">
  <img src={'https://www.thecocktaildb.com/images/ingredients/gin-small.png'} width="100" class="lazy lazy-fade-in" alt="Gin"/>
    <p>
    {#if brands.gin}
      {#each brands.gin.list as t}
      {t.name},
      {/each}
    {:else}
      {notFound}
    {/if}
    </p>
</Block>
<BlockTitle>Whiskey</BlockTitle>
<Block strong inset class="alcohol-list">
  <img src={'https://www.thecocktaildb.com/images/ingredients/whiskey-small.png'} width="100" class="lazy lazy-fade-in" alt="Gin"/>
    <p>
    {#if brands.whiskey}
      {#each brands.whiskey.list as t}
      {t.name},
      {/each}
    {:else}
      {notFound}
    {/if}
    </p>
</Block>
  {:else}
    {#each loadDummy as d}
    <BlockTitle class="skeleton-text skeleton-effect-fade">Vodka</BlockTitle>
    <Block strong inset class="alcohol-list">
     <SkeletonBlock style="width: 90px; height: 75px; margin-right:10px; " class="skeleton-block skeleton-effect-fade" />
        <p class="skeleton-text skeleton-effect-fade">sd;gfld;fgl, sdldflskdflsk, sldfksldfk, sdflksdlfksdf, sdlfksdflskf, sdflksdfls, sdlfksdf, 
        sdflskdfl,swdfsldfk,sdsdddddddddddsdds
       </p>
      </Block>
    {/each}
  {/if}
  
</Page>
<script>
 import { f7,f7ready, Page,Link,List,ListItem, Popup,NavRight,CardFooter,Row,Col,PhotoBrowser,Navbar, BlockTitle, Block,Card,CardHeader,CardContent,SkeletonBlock,Chip } from 'framework7-svelte';
import {place,venueAlcoholList} from '../js/store'
import { onMount } from 'svelte';
import {getLocDoc} from '../js/core'
import {firestoreInstance} from '../components/dts-shared-components/firebase/store'
let loading=true;
let loadDummy=[1,2,3,4,5];
let brands={};
let notFound="Sorry, this list has not been updated yet. Please check with your server.";
let tequila=null;

function loadAlcoholList(){
   loading=false;
   let loadAgain=false;

   let alcoholList=$venueAlcoholList
   if (alcoholList){
     if (alcoholList.lid != $place.id) loadAgain=true;
     else
      brands=alcoholList;
   }else{
     loadAgain=true;
   }
  if (loadAgain){
    loading=true;
    let locDocRef=$firestoreInstance.collection(`locations/${$place.id}/alcohol`);
    let tref=locDocRef.doc('tequila')
    let vref=locDocRef.doc('vodka')
    let gref=locDocRef.doc('gin')
    let rref=locDocRef.doc('rum')
    let wref=locDocRef.doc('whiskey')
    brands.lid=$place.id;
    tref.get()
    .then((d)=>{
      if (d.exists) return d.data();
      else return null;
    })
    .then((d)=>{
      brands.tequila=d;
    })
    vref.get()
    .then((d)=>{
      if (d.exists) return d.data();
      else return null;
    })
    .then((d)=>{
      brands.vodka=d;
    })
    gref.get()
    .then((d)=>{
      if (d.exists) return d.data();
      else return null;
    })
    .then((d)=>{
      brands.gin=d;
    })
  rref.get()
    .then((d)=>{
      if (d.exists) return d.data();
      else return null;
    })
    .then((d)=>{
      brands.rum=d;

    })
  wref.get()
    .then((d)=>{
      if (d.exists) return d.data();
      else return null;
    })
    .then((d)=>{
      brands.whiskey=d;
      venueAlcoholList.set(brands);
      loading=false;
    })
  }

}

function onPageInit(){
  loadAlcoholList();
}
onMount(() => {
 
});
</script>