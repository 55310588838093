
import * as geofirestore from 'geofirestore';
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
import i18next from 'i18next';
import {f7} from 'framework7-svelte'
import {userToken} from '../components/dts-shared-components/auth/store'

import {reqService,postService} from '../components/dts-shared-components/core/functions'

let authToken=null;
userToken.subscribe(value => {
	authToken = value;
});

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response.json();
}

export async function searchDrinks(type){
	console.log(`https://us-central1-fiesta-drinks.cloudfunctions.net/searchDrinks`)
	let url=`https://us-central1-fiesta-drinks.cloudfunctions.net/searchDrinks`;
  return reqService(url,`type=${type}`,"",false)
  .then((data)=>{
	  return data;
  })
  .catch(function(ex) {
	  console.error(ex)
	  throw Error(ex);
  })
}

export async function grabTable(lid,qrKey,uid,uname,pic,isShort,msgToken,avatar){
	let body=JSON.stringify({lid:lid,qrKey: qrKey,uid: uid,uname: uname, pic: pic,isShort,msgToken,avatar});
	console.log(`https://us-central1-fiesta-drinks.cloudfunctions.net/grabTable`)
	return postService('https://us-central1-fiesta-drinks.cloudfunctions.net/grabTable',body,'Confirming table..',true)
}

export async function sendOrder(req){
	let body=JSON.stringify(req);
	console.log(`https://us-central1-fiesta-drinks.cloudfunctions.net/sendOrder`)
	return postService('https://us-central1-fiesta-drinks.cloudfunctions.net/sendOrder',body,'Sending order...',true)
}
export async function updateTableUser(id,avatar,name,pic){
	let req={id,avatar,name,pic};
	let body=JSON.stringify(req);
	console.log(`https://us-central1-fiesta-drinks.cloudfunctions.net/updateTableUser`)
	return postService('https://us-central1-fiesta-drinks.cloudfunctions.net/updateTableUser',body,null)
}
export async function getDrink(id){
	let qs="id=" + id;
	console.log(`https://us-central1-fiesta-drinks.cloudfunctions.net/getDrink`)
	return reqService('https://us-central1-fiesta-drinks.cloudfunctions.net/getDrink',qs,"",true)
}


export async function getInternalCocktails(db,type){
	let cocktailsRef=db.collection(`cocktails2`);
	return cocktailsRef.doc(type)
	.get()
	.then((doc)=>{
		if (doc.exists){
			return doc.data();
		}else
			return null;
	})
	.then((data)=>{
		return data.list;
	})

}

export async function getLocDoc(db,lid,coll,doc){
	let locDocRef=db.collection(`locations/${lid}/${coll}`);
	return locDocRef.doc(doc)
	.get()
	.then((d)=>{
		if (d.exists) return d.data();
		else return null;
	})
	.catch((e)=>{
		return null;
	})
}
/*
export async function getAlcDoc(db,lid,doc){
	let brandsRef=db.collection(`locations/${lid}/alcohol`);
  
	let tequilaref=brandsRef.doc('tequila');
	let vodkaref=brandsRef.doc('vodka');
	let rumref=brandsRef.doc('rum');
	let ginref=brandsRef.doc('gin');
	let wref=brandsRef.doc('whiskey');
	let bref=brandsRef.doc('brandy');
  
	let doc={
	  tequila:{list:[]},
	  vodka:{list:[]},
	  rum:{list:[]},
	  gin:{list:[]},
	  whiskey:{list:[]},
	  brandy:{list:[]}
	};
	await tequilaref.get()
	.then((qs)=> {
	  if (qs.exists){
		doc.tequila=qs.data();
	  }
	})
	await vodkaref.get()
	.then((qs)=> {
	  if (qs.exists){
		doc.vodka=qs.data();
	  }
	});
	await rumref.get()
	.then((qs)=> {
	  if (qs.exists){
		doc.rum=qs.data();
	  }
	});
	await wref.get()
	.then((qs)=> {
	  if (qs.exists){
		doc.whiskey=qs.data();
	  }
	});
	await ginref.get()
	.then((qs)=> {
	  if (qs.exists){
		doc.gin=qs.data();
	  }
	});
	await bref.get()
	.then((qs)=> {
	  if (qs.exists){
		doc.brandy=qs.data();
	  }
	});

	return doc;
	return window.fetch(`https://us-central1-fiesta-drinks.cloudfunctions.net/${doc}?id=${loc}`)
    .then(function(response) {
        return response.json()
    }).then(function(json) {
        console.log(json)
        return json;
    }).catch(function(ex) {
        console.log("error",ex)
    })
}*/
export function addPlace(firestore,name,address,city,st,pic,lat,lng){
	const GeoFirestore = geofirestore.initializeApp(firestore);
	const locations = GeoFirestore.collection('locations');
	locations.add({
		name: name,
		pic: pic,
		address:address,
		city:city,
		st:st,
		// The coordinates field must be a GeoPoint!
		coordinates: new firebase.firestore.GeoPoint(lat,lng)
	  });

}
export function getNearbyPlaces(firestore,lat,lng,radius,limit){
    if (!radius) radius=5.0
    if (!limit) limit=10
    const GeoFirestore = geofirestore.initializeApp(firestore);
	const locations = GeoFirestore.collection('locations');
    return new Promise( (resolve,reject) => {
        const query = locations.near({ center: new firebase.firestore.GeoPoint(lat, lng), radius: radius}).limit(limit);
        // Get query (as Promise)
        return query.get().then((value) => {
            // All GeoDocument returned by GeoQuery, like the GeoDocument added above
            const docs=value.docs.map((doc)=>{
                doc['data']=doc['data']();
                return doc;
            })
            if (docs.length > 0){
                const sorted = docs.sort((a, b) => a.distance - b.distance);
                resolve(sorted);
            }else{
                resolve([]);
            }
        })
        .catch(()=>{
            reject();
        })
    });
}

export function loadScript(url){
	return new Promise( (resolve,reject) => {
		let script = document.createElement("script");
		script.type = "text/javascript";
		script.src = url;

		script.onload=()=>{
			resolve(url);
		};
		script.onerror=()=>{
			reject();
		}
		document.body.appendChild(script);
	});
}




'use strict';

/*
|------------------------------------------------------------------------------
| FastClick
|------------------------------------------------------------------------------
*/
/*
Framework7.utils.fastClick = {

	enable: function(el) {
		app.$(el).removeClass('no-fastclick');
	},

	disable: function(el) {
		app.$(el).removeClass('no-fastclick');
		app.$(el).addClass('no-fastclick');
	}

}
*/
/*
|------------------------------------------------------------------------------
| i18n
|------------------------------------------------------------------------------
*/
export function initializeI18n() {
    var language = Utili18n.getLanguage();
	i18next
		.use(i18nextXHRBackend)
		.init({
		lng: language.lang,
		fallbackLng: 'en',
		whitelist: ['en', 'hi', 'ar'],
		nonExplicitWhitelist: true,
		preload: ['en', 'hi', 'ar'],
		backend: {
			loadPath: 'assets/custom/i18n/{{lng}}.json'
		}
	},
					function() {
                        Utili18n.setLanguage(language);
	});
}
/*
export const Utili18n = {

	getLanguage: function() {
		var language = localStorage.getItem('JustDrinks_Language') ? JSON.parse(localStorage.getItem('JustDrinks_Language')) : window.config.i18n;
		return language;
	},

	setLanguage: function(language) {
		var language = language ? language : window.config.i18n;
		var isCurrentRtl = false; // MDO: temp

		i18next.changeLanguage(language.lang, function() {
			localStorage.setItem('JustDrinks_Language', JSON.stringify(language));

			app.$('html').attr('lang', language.lang);
			app.$('html').attr('dir', language.dir);

			if (language.dir == 'rtl') {
				app.$('.f7[rel=stylesheet]').attr('href', 'framework7.rtl.min.css');
				app.params.rtl = true;
			}
			else {
				app.$('.f7[rel=stylesheet]').attr('href', 'framework7.min.css');
				app.params.rtl = false;
			}

			window.localize = locI18next.init(i18next);
			window.localize('body');

			if (isCurrentRtl != app.params.rtl) {
				app.view.main.router.navigate('/');
			}
		});
	}

};
*/

/*
|------------------------------------------------------------------------------
| Theme
|------------------------------------------------------------------------------
*/

Framework7.utils.theme = {

	getColor: function() {
		var color = localStorage.getItem('JustDrinks_Theme_Color') ? localStorage.getItem('JustDrinks_Theme_Color') : window.config.theme.color;
		return color;
	},

	getLayout: function() {
		var layout = localStorage.getItem('JustDrinks_Theme_Layout') ? localStorage.getItem('JustDrinks_Theme_Layout') : window.config.theme.layout;
		return layout;
	},

	setColor: function(color) {
		var color = color ? color : window.config.theme.color;
        console.log("set",app)
		localStorage.setItem('JustDrinks_Theme_Color', color);

		app.$$('body').removeClass('color-theme-red color-theme-green color-theme-blue color-theme-pink color-theme-yellow color-theme-orange color-theme-gray color-theme-black');
		app.$$('body').addClass('color-theme-' + color);

    this.setStatusBarColor();
	},

	setLayout: function(layout) {
		var layout = layout ? layout : window.config.theme.layout;

		localStorage.setItem('Nectar_Theme_Layout', layout);

		app.$('body').removeClass('theme-light theme-dark');
		app.$('body').addClass('theme-' + layout);

    this.setStatusBarColor();
	},

  setStatusBarColor: function() {
    var color = this.getColor();
    var layout = this.getLayout();
    var statusBarColor = null;

    if (app.theme == 'ios') {
      if (layout == 'dark') {
        statusBarColor = window.config.colors[app.theme]['statusbar_dark'];
      }
      else {
        statusBarColor = window.config.colors[app.theme]['statusbar_light'];
      }
    }
    else {
      statusBarColor = window.config.colors[app.theme]['statusbar_' + color];
    }

		app.$('meta[name=theme-color]').attr('content', statusBarColor);
		/*
		if (app.device.cordova) {
			document.addEventListener('deviceready', function() {
				StatusBar.backgroundColorByHexString(statusBarColor);
			});
	  }*/
  }

};

/*
|------------------------------------------------------------------------------
| Web API Support
|------------------------------------------------------------------------------
*/

Framework7.support.webApi = {

	batteryStatus: (function() { if ('getBattery' in navigator || 'battery' in navigator) { return true; } else { return false; } }) (),

	clipboard: (function() { if ('clipboard' in navigator) { return true; } else { if (document.queryCommandSupported('cut') || document.queryCommandSupported('copy') || document.queryCommandSupported('paste')) { return true; } else { return false; } } }) (),

	deviceMemory: (function() { if ('deviceMemory' in navigator) { return true; } else { return false; } }) (),

	deviceOrientation: (function() { if ('DeviceOrientationEvent' in window && 'DeviceMotionEvent' in window) { return true; } else { return false; } }) (),

	file: (function() { if ('FileReader' in window || 'File' in window) { return true; } else { return false; } }) (),

	fullscreen: (function() { if ('requestFullscreen' in document.documentElement || 'webkitRequestFullscreen' in document.documentElement || 'mozRequestFullScreen' in document.documentElement || 'msRequestFullscreen') { return true; } else { return false; } }) (),

	geolocation: (function() { if ('geolocation' in navigator) { return true; } else { return false; } }) (),

	networkInformation: (function() { if ('connection' in navigator || 'webkitConnection' in navigator || 'mozConnection' in navigator) { return true; } else { return false; } }) (),

	onlineOfflineStatus: (function() { if ('onLine' in navigator) { return true; } else { return false; } }) (),

	pageVisibility: (function() { if (typeof document.hidden !== 'undefined' || typeof document.webkitHidden !== 'undefined' || typeof document.mozHidden !== 'undefined' || typeof document.msHidden !== 'undefined') { return true; } else { return false; } }) (),

	permissions: (function() { if ('permissions' in navigator) { return true; } else { return false; } }) (),

	quotaEstimation: (function() { if ('storage' in navigator && 'estimate' in navigator.storage) { return true; } else { return false; } }) (),

	screenOrientation: (function() { if ('orientation' in screen || 'mozOrientation' in screen || 'msOrientation' in screen) { return true; } else { return false; } }) (),

	serverSentEvents: (function() { if ('EventSource' in window) { return true; } else { return false; } }) (),

	vibration: (function() { if ('vibrate' in navigator) { return true; } else { return false; } }) (),

	webShare: (function() { if ('share' in navigator) { return true; } else { return false; } }) ()

};