
//import HomePage from '../pages/home.svelte';
import SplashPage from '../pages/splash.svelte';
import LocPage from '../pages/loccheck.svelte';
import DrinksPage from '../pages/drinks.svelte';
import AlcoholPage from '../pages/alcohol.svelte';
import ExplorePage from '../pages/explore.svelte';
import VenuePage from '../pages/venue.svelte';
import FeaturedPage from '../pages/featured.svelte';
import AboutPage from '../pages/about.svelte';
import StartPage from '../pages/start.svelte';
//import ProfilePage from '../pages/profile.svelte';
import MorePage from '../pages/more.svelte';
import FormPage from '../pages/form.svelte';
//import AskPage from '../pages/ask.svelte';
//import AlertsPage from '../pages/alerts.svelte';
import CatalogPage from '../pages/catalog.svelte';
import ProductPage from '../pages/product.svelte';
import SettingsPage from '../pages/settings.svelte';
import QRCRecPage from '../pages/qrcreceive.svelte';
import AddToTablePage from '../pages/addToTable.svelte';
import DynamicRoutePage from '../pages/dynamic-route.svelte';
import RequestAndLoad from '../pages/request-and-load.svelte';
import NotFoundPage from '../pages/404.svelte';
import CSPage from '../pages/parked.svelte';
import PWT from '../pages/walkthrough.svelte';
import TP from '../pages/testpage.svelte';
import FavsPage from '../pages/fav.svelte';
//import QRScanPage from '../pages/qrscan.svelte';
import SponsorPage from '../pages/sponsor.svelte';
import {handleAsyncLogin,loginAnonymously,silentAnonymousLogin } from '../components/dts-shared-components/auth/core'
import {lastScannedCode,qrScanConfirmed,userInfo} from '../js/store'
import {geoFirestoreLoaded,gotPosition} from '../components/dts-shared-components/location/store'

let gotLoc=false;


var routes = [
  {
    path: '/',
    //component: SplashPage,
    name:'homeroot',
    async: function(routeTo,routeFrom, resolve,reject) {
      var router = this;
      var app = router.app;
      app.$('.main-toolbar').addClass('toolbar-hidden');
      //   console.log("qr aync, route to");
        let c=routeTo.query.c;
        if (c != null){
            window.history.pushState({}, app.name, "/"); 
            lastScannedCode.set(c);   
            qrScanConfirmed.set(false);
            //window.sessionStorage.setItem("gotQRC",c)
            resolve({component: SplashPage});
            //handleAsyncLogin(app,resolve,reject,SplashPage);
        }else{
          //handleAsyncLogin(app,resolve,reject,SplashPage);
           resolve({component: SplashPage});
        }
    }
   // async: function(routeTo,routeFrom, resolve,reject) {
   //   console.log("home aync, route to");
   //   console.log(routeTo.query)
   //   resolve();
   // },
   /*
    beforeEnter: function(to, from, resolve, reject) {
      var router = this;
      var app = router.app;
      let gotLoc=localStorage.getItem('gotLoc');
      
      if (gotLoc && gotLoc == "true"){

        resolve();
      }else{
        reject();
        router.navigate('/loc/');
        app.$(".main-toolbar").hide();
        return;
      }
    }
    */
  },
  {
    path: '/qr/:id/',
    //component: QRCRecPage,
    //url:"http://localhost:5000/qr/?c={id}"
    //name: 'home',
    //component: HomePage,
    async: function(routeTo,routeFrom, resolve,reject) {
    //   console.log("qr aync, route to");
     console.log(routeTo.query)
      resolve({componentUrl: '/qr/1'});
    },
  },
  {
    path: '/home/',
    name: 'home',
    async: function(routeTo,routeFrom, resolve,reject) {
      var router = this;
      var app = router.app;
      let geoLoaded=false;
      gotPosition.subscribe(value => {
        if (!value){
          resolve({component: LocPage},{reloadCurrent: true});
        }else{
          silentAnonymousLogin(app)
          .then((user)=>{
            geoFirestoreLoaded.subscribe(value => {
              if (value){
                import('../pages/home.svelte').then((module) => {
                  // resolve with component
                  resolve({ component: module.default },{reloadCurrent: true})
                });
              }
            });
          })
        }
      });
      window.setTimeout(()=>{
          reject();
      },30000)
    },
    on: {
      pageAfterIn: function test (e, page) {
          // After page views home, lazy load more stuff.
          console.log("home page in")
      },
    }
  },
  /*
  {
    path: '/home/',
    name: 'home',
    async: function(routeTo,routeFrom, resolve,reject) {
      var router = this;
      var app = router.app;
      handleAsyncLogin(app,false)
      .then((loggedIn)=>{
        if (loggedIn){
          resolve({component: HomePage},{reloadCurrent: true});
          return;
        }else{
          // For this app we have chosen not to do initial signup page so just login anonymously and continue.
         loginAnonymously()
         .then((user)=>{
          let fs=firebase.firestore();
          let userRef=fs.collection(`users`);
          userRef.doc(user.uid).get()
          .then((doc)=>{
            if (doc && !doc.exists){
              let ui={created: new Date(),birthMonth:'',birthYear:'',ageConfirmed:false,themeDark: true,defaultAvatar:'9'};
              userRef.doc(user.uid).set(ui)
              .then(()=>{
                userInfo.set(ui);
                resolve({component: HomePage},{reloadCurrent: true});
              });
            }else{
              resolve({component: HomePage},{reloadCurrent: true});
            }
          })
          
         
        });
      }
      },(e)=>{
        reject();
      })
      .catch((e)=>{
        console.log("error",e)
        reject();
      })
    }
    //component: HomePage,
  },
  */
  {
    path: '/favorites/',
    name: 'fav',
    component: FavsPage
  },
  {
    path: '/qrscan/',
    name: 'qrscan',
    async: function(routeTo,routeFrom, resolve,reject) {
      import('../pages/qrscan.svelte').then((module) => {
        // resolve with component
        resolve({ component: module.default })
      });
    }
  },
  {
    path: '/addToTable/',
    name: 'addfriend',
    component: AddToTablePage
  },
  {
    path: '/coming/',
    name: 'comingsoon',
    component: CSPage
  },
  {
    path: '/more/',
    name: 'settings',
    component: MorePage
  },
  {
    path: '/profile/',
    name: 'profile',
    async: function(routeTo,routeFrom, resolve,reject) {
      import('../pages/profile.svelte').then((module) => {
        // resolve with component
        resolve({ component: module.default })
      });
    }
  },
  {
    path: '/alerts/',
    name: 'alerts',
    async: function(routeTo,routeFrom, resolve,reject) {
      import('../pages/alerts.svelte').then((module) => {
        // resolve with component
        resolve({ component: module.default })
      });
    }
  },
  {
    path: '/splash/',
    name: 'splash',
    component: SplashPage,
  },
  {
    path: '/testpage/',
    name: 'testpage',
    component: TP,
  },
  {
    path: '/walkthrough/',
    name: 'walkthrough',
    component: PWT,
  },
  {
    path: '/loc/',
    name: 'loc',
    component: LocPage,
  },
  {
    path: '/ask/',
    name: 'ask',
    async: function(routeTo,routeFrom, resolve,reject) {
      import('../pages/ask.svelte').then((module) => {
        // resolve with component
        resolve({ component: module.default })
      });
    }
  },
  {
    path: '/featured/',
    component: FeaturedPage,
  },
  {
    path: '/venue/:id/',
    component: VenuePage,
    name: 'venue'
  },
  {
    path: '/sponsor/',
    component: SponsorPage,
    name: 'sponsor'
  },
  {
    path: '/drinks/:cat/',
    component: DrinksPage,
  },
  {
    path: '/alcohol/',
    component: AlcoholPage,
  },
  {
    path: '/explore/',
    component: ExplorePage,
  },
  {
    path: '/catalog/',
    component: CatalogPage,
  },
  {
    path: '/product/:id/',
    component: ProductPage,
  },
  {
    path: '/settings/',
    component: SettingsPage,
  },

  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
