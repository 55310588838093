<Page name="drinks">
  <Navbar title={catName} backLink="Back" />
  <BlockTitle>{catName} Drinks</BlockTitle>
  <Block><small>Note: These drink suggestions may not be available at this location. Please check with your server for drink availability.</small></Block>

<div class="drink-cards">
<DrinkCards loading={catLoading} drinks={catDrinks} on:openPhoto={photoClick} on:openDetails={detailsClick}></DrinkCards>
</div>

<DrinkPopup drink={curDrink}></DrinkPopup>
<PhotoBrowser photos={photos} theme="dark" bind:this={photoBrowser} />
</Page>
<script>
import fetch from 'whatwg-fetch'

  import { f7,f7ready, Page,Link, Popup,NavRight,CardFooter,Row,Col,PhotoBrowser,Navbar, BlockTitle, Block,Card,CardHeader,CardContent,SkeletonBlock,Chip } from 'framework7-svelte';
  import {curCat,Drinks,popularCocktails,latestCocktails} from '../js/store'
 import {tick} from 'svelte'
 import DrinkPopup from '../components/popup-drink.svelte'
 import {firestoreInstance} from '../components/dts-shared-components/firebase/store'

 import {getInternalCocktails} from '../js/core'
  export let f7route;
  let photoBrowser
  let photos
  let skeletonCards=[1,2,3,4]

  
  let catLoading=false;
  let catDrinks=[1,2,3,4,5,6,7];
  let catName;
  let lastCat;
  let curDrink;
  let openPopup=false;

import DrinkCards from '../components/drinks-card.svelte'

$: if (catDrinks.length){
    Drinks.set(catDrinks)
    tick().then(()=>{
         f7.lazy.create('.drink-cards');
    });
    
     console.log('drink-cards')
    let pTmp=[]
    // Reload photos for photo browser
    catDrinks.forEach(function (d) {
        pTmp.push({url:d.pic, caption: d.name})
    });
    photos=pTmp;

}
  f7ready(() => {
     
    catName = f7route.params.cat;
    
    if ($curCat == catName) {
        catDrinks=$Drinks
    }else{
        catLoading=true
        curCat.set(catName)
        let tName=catName.toLowerCase();
        switch(tName){
            case "fruity":
                getInternalCocktails($firestoreInstance,'fruity')
                .then((drinks)=>{
                     catDrinks=drinks
                     catLoading=false
                })
                /*
                if ($latestCocktails == null)
                    searchDrinks('latestCocktails').then((drinks)=>{
                        catDrinks=drinks
                        latestCocktails.set(drinks)
                        catLoading=false
                    });
                 else{
                     catDrinks=$latestCocktails;
                     catLoading=false
                 }
                 */
                 break;
            case "popular":
                 if ($popularCocktails == null)
                    searchDrinks('popularCocktails').then((drinks)=>{
                        catDrinks=drinks
                        popularCocktails.set(drinks)
                        catLoading=false
                    });
                 else{
                     catDrinks=$popularCocktails;
                     catLoading=false
                 }
                break;
            case "virgin":
                searchDrinks('virginCocktails').then((drinks)=>{
                        catDrinks=drinks
                        catLoading=false
                });
                break;
            case "featured":
                searchDrinks('featuredDrinks').then((drinks)=>{
                        catDrinks=drinks
                        catLoading=false
                        //console.log('drinks',drinks)
                        })
                break;


        }
    }
    

  });

  function searchDrinks(type){
      console.log(`https://us-central1-fiesta-drinks.cloudfunctions.net/${type}`)
    return window.fetch(`https://us-central1-fiesta-drinks.cloudfunctions.net/${type}`)
    .then(function(response) {
        return response.json()
    }).then(function(json) {
        console.log(json)
        return json;
    }).catch(function(ex) {
        console.log("error",ex)
    })
 }
 function photoClick(e){
    let i=e.detail.index;

    if (photos.length){
        photoBrowser.open(i);
    }
 }
 function detailsClick(e){
     console.log("details click",e)
     let d=e.detail.drink;
     curDrink=d;
    f7.popup.open(".drink-detail-popup");
 }

</script>