

<Page name="sponsor" class="stretch-content" noSwipeback onPageInit={onPageInit} noToolbar noNavbar>
<Block class="text-align-center">
<h2>Hang tight while we setup your table...</h2>

<!-- QrScan-Ad -->
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" on:load={loaded}></script>
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-7668134571514145"
     data-ad-slot="2682597990"
     data-ad-format="vertical"
     data-full-width-responsive="true"></ins>

</Block>

</Page>
<script>
function loaded(){
     (adsbygoogle = window.adsbygoogle || []).push({});

      window.setTimeout(()=>{
             f7.views.main.router.navigate("/venue/0/",{reloadCurrent: true});
      },5000)
 
}

function onPageInit(){
       
}
import { f7,f7ready,Page, Navbar, Block, BlockTitle,Button,Swiper,SwiperSlide,List,ListItem,ListInput} from 'framework7-svelte';
   
</script>