<Page name="venue" on:pageInit={onPageInit} pageContent={false}>
 {#if venue}
  <Navbar title={venue.name} backLink="Back" subtitle={venue.tagLine} sliding>
    
      <!---
    <Subnavbar class="subnav-venue">
      {#if venue.server && $venueOrder}
        <div class="venue-server">
          
              <div class="display-flex align-items-center justify-content-space-between">
                <div class="user-small display-inline-flex align-items-center">
                  <img src={$user.photoURL} alt="Server" width="40px" />
                  <div class="user-infos">
                    <div class="user-name">{$venueOrder.ownerName + ' table'}</div>
                    <div class="user-info"><b>{$venueOrder.users.length}</b> person(s) checked in at table #{$lastScannedVenue.tableNo}</div>
                  </div>
                </div>
                <a class="rate-badge bg1 display-inline-flex align-items-center link popup-open" href="#1" data-popup=".popup-rate">
                  <img src="images/star2.png" alt="rate">
                  <span>{venue.server.rating}</span>
                </a>
              </div
        </div>
      {/if}
    </Subnavbar>  
    >-->
 
  </Navbar>
  <Toolbar tabbar position='top' scrollable>
   <Link tabLink="#t1" tabLinkActive text="Cocktails"></Link>
    <Link tabLink="#t12" text="Non-Alcoholic"></Link>
         <Link tabLink="#t3" text="Wine">
       </Link>
       {#each dynTabData as td}
        <Link tabLink={`#${td.id}`} text={td.name}>
       </Link>
       {/each}
       
     
  </Toolbar>
  
  
  <!--
  <div style="position: relative; margin-top:40px" class="page-content venue-page-content">
      <BlockTitle>Browse categories</BlockTitle>
      <Block style="margin-bottom:0;">
        <Swiper class="swiper-category" params={{slidesPerView: "3.65", spaceBetween: 5}}>
          <SwiperSlide>
            <IconCatButton src="https://img.imageboss.me/justdrinks/height/30/cocktail-icon.png" round figure={true} heading="Cocktails" link="#1" tabLink={true} active={true} bgColor="#d2f8d2" width="auto" height="30px"  />
          </SwiperSlide>
          <SwiperSlide>
            <IconCatButton link="#2" tabLink={true} round figure={true} heading="Beer" active={false} src='https://img.imageboss.me/justdrinks/height/30/beer-icon2.png' bgColor="#fcead4" width="auto" height="30px" />
          </SwiperSlide>
            <SwiperSlide>
            <IconCatButton link="#3" tabLink={true} round figure={true} heading="Wine" active={false} src="https://img.imageboss.me/justdrinks/height/30/wine-icon.png" bgColor="#ffd9e8" width="auto" height="30px" />
          </SwiperSlide>
            <SwiperSlide>
            <IconCatButton link="#4" tabLink={true} round figure={true} heading="Shots" src='https://firebasestorage.googleapis.com/v0/b/fiesta-drinks.appspot.com/o/shot-icon.png?alt=media&token=98875f20-902d-47fc-b550-f523458c161c' bgColor="#eaedfc" width="auto" height="30px" />
          </SwiperSlide>
        </Swiper>
      </Block>
   -->   
      <Tabs animated>
        <div id="t1" class="page-content tab tab-active" style="padding-bottom:200px;margin-top:108px;">
        
                        <BlockTitle medium>Featured Cocktails</BlockTitle>
                        {#if venue.cocktails.featured}
                                <Swiper class="swiper-featured" pagination params={{speed: 300,slidesPerView: "2.3",effect:'coverflow',cubeEffect: {  slideShadows: true},pagination:{dynamicBullets: true}}}>
                                {#each venue.cocktails.featured as fi,index}
                                    <SwiperSlide>
                                    <CardImg id={index} cornerRadius="8px" height="150px" content={false} bold topHeadingTitle={fi.name} fadeOut imgUrl={'https://img.imageboss.me/cocktails/height/150/' + fi.pic} on:cardClicked={(e)=> clickDrink('featured',e.detail.id)}>
                                    </CardImg>
                                    </SwiperSlide>
                                  {/each}
                                </Swiper>
                        {:else}
                            <Swiper class="skeleton-text skeleton-effect-fade" params={{slidesPerView:"2.3",effect:'coverflow'}}>
                                <SwiperSlide><SkeletonBlock style="height: 150px"></SkeletonBlock></SwiperSlide>
                                <SwiperSlide><SkeletonBlock style="height: 150px"></SkeletonBlock></SwiperSlide>
                                <SwiperSlide><SkeletonBlock style="height: 150px"></SkeletonBlock></SwiperSlide>
                            </Swiper>
                        {/if}
                        <BlockTitle>Popular</BlockTitle>
                        {#if venue.cocktails.popular}
                            <center>
                            <Swiper params={{slidesPerView: '3.8',spaceBetween: 2,speed: 700,centeredSlides:true,centeredSlidesBounds:true,pagination:{dynamicBullets: true}}}>
                            {#each venue.cocktails.popular as l,index}
                              <SwiperSlide>
                                <IFeature w="75px" h="75px" title={l.name} id={index} pic={'https://img.imageboss.me/cocktails/width/75/' + l.pictn} lazy on:featureClicked={(e)=> clickDrink('popular',e.detail.id)}></IFeature>
                              </SwiperSlide>
                            {/each}
                            </Swiper>
                            </center>
                        {:else}
                           <Swiper class="skeleton-text skeleton-effect-fade" params={{slidesPerView:"3.8",spaceBetween:2}}>
                                <SwiperSlide><IFeature w="75px" h="75px" loading={true}></IFeature></SwiperSlide>
                                <SwiperSlide><IFeature w="75px" h="75px" loading={true}></IFeature></SwiperSlide>
                                <SwiperSlide><IFeature w="75px" h="75px" loading={true}></IFeature></SwiperSlide>
                                <SwiperSlide><IFeature w="75px" h="75px" loading={true}></IFeature></SwiperSlide>
                                <SwiperSlide><IFeature w="75px" h="75px" loading={true}></IFeature></SwiperSlide>
                            </Swiper>
                        {/if}
                        {#if venue.cocktails.latest}
                              <BlockTitle>Latest Cocktails</BlockTitle>
                                <center>
                                <Swiper params={{slidesPerView: '3.8',spaceBetween: 2}}>
                                {#each venue.cocktails.latest as l,index}
                                  <SwiperSlide>
                                    <IFeature w="75px" h="75px" title={l.name} id={index} pic={'https://img.imageboss.me/cocktails/width/75/' + l.pictn} lazy on:featureClicked={(e)=> clickDrink('latest',e.detail.id)}></IFeature>
                                  </SwiperSlide>
                                {/each}
                              </Swiper>
                              </center>
                        {:else}
                           <Swiper class="skeleton-text skeleton-effect-fade" params={{slidesPerView:"3.8",spaceBetween:2}}>
                                <SwiperSlide><IFeature w="75px" h="75px" loading={true}></IFeature></SwiperSlide>
                                <SwiperSlide><IFeature w="75px" h="75px" loading={true}></IFeature></SwiperSlide>
                                <SwiperSlide><IFeature w="75px" h="75px" loading={true}></IFeature></SwiperSlide>
                                <SwiperSlide><IFeature w="75px" h="75px" loading={true}></IFeature></SwiperSlide>
                                <SwiperSlide><IFeature w="75px" h="75px" loading={true}></IFeature></SwiperSlide>
                            </Swiper>
                        {/if}
                  </div>
                  <Tab id="t3" class="page-content" on:tabShow={GetVenueWine} >
                    <BlockTitle medium>Wine</BlockTitle>
                    {#if venue.wine}
                      {#if venue.wine.reds}
                        <BlockTitle>Reds</BlockTitle>
                        <Swiper params={{slidesPerView: 3}}>
                                  {#each venue.wine.reds as l,index}
                                    <SwiperSlide>
                                        <IFeature w="80px" h="80px" title={l.name + '- ' + l.style} id={l.index} pic={'https://img.imageboss.me/justdrinks/width/80/' + l.pic} on:featureClicked={(e)=> clickDrink(l.type,e.detail.id)}></IFeature>
                                    </SwiperSlide>
                                  {/each}
                        </Swiper>
                      {/if}
                       {#if venue.wine.whites}
                        <BlockTitle>Whites</BlockTitle>
                        <Swiper params={{slidesPerView: 3}}>
                                  {#each venue.wine.whites as l,index}
                                    <SwiperSlide>
                                        <IFeature w="80px" h="80px" title={l.name + '- ' + l.style} id={l.index} pic={'https://img.imageboss.me/justdrinks/width/80/' + l.pic} on:featureClicked={(e)=> clickDrink(l.type,e.detail.id)}></IFeature>
                                    </SwiperSlide>
                                  {/each}
                        </Swiper>
                      {/if}
                        {#if venue.wine.sparkling}
                        <BlockTitle>Sparkling</BlockTitle>
                        <Swiper params={{slidesPerView: 3}}>
                                  {#each venue.wine.sparkling as l,index}
                                    <SwiperSlide>
                                        <IFeature w="80px" h="80px" title={l.name + '- ' + l.style} id={l.index} pic={'https://img.imageboss.me/justdrinks/width/80/' + l.pic} on:featureClicked={(e)=> clickDrink(l.type,e.detail.id)}></IFeature>
                                    </SwiperSlide>
                                  {/each}
                        </Swiper>
                      {/if}
                    {/if}
                  </Tab>
                  {#each dynTabData as dt}
                    <Tab id={dt.id} class="page-content" on:tabShow={(e)=> GetDrinks(dt.type)}>
                        <BlockTitle medium>{dt.name}</BlockTitle>
                        {#if venue[dt.type]}
                            <center>
                                <Swiper params={{slidesPerView: 3}}>
                                {#each venue[dt.type] as l,index}
                                  <SwiperSlide>
                                    {#if l.internal}
                                      <IFeature w="75px" h="75px" title={l.name} id={index} pic={'https://img.imageboss.me/justdrinks/width/90/' + l.pic} on:featureClicked={(e)=> clickDrink(dt.type,e.detail.id)}></IFeature>
                                    {:else}
                                      <IFeature w="75px" h="75px" title={l.name} id={index} pic={'https://img.imageboss.me/cocktails/width/90/' + l.pic} on:featureClicked={(e)=> clickDrink(dt.type,e.detail.id)}></IFeature>
                                    {/if}
                                  </SwiperSlide>
                                {/each}
                              </Swiper>
                            </center>
                        {/if}
                        {#if venue.brands[dt.type]}
                          <BlockTitle>{dt.name} List</BlockTitle>
                          <Block style="text-align:center">
                          <Row>
                          {#each venue.brands[dt.type].list as b,index}
                            <Col width="50">{b.name}</Col>
                          {/each}
                          </Row>
                          </Block>
                        {/if}
                    </Tab>
                  {/each}
                   <Tab id="t12" class="page-content">
                    <BlockTitle>Non-Alcoholic</BlockTitle>
                    <Block>
                      <p>Please see your server for a list of non-alcoholic beverages.</p>
                    </Block>
                   </Tab>
          </Tabs>
          <Block style="height: 50px">

          </Block>


 {/if}
 
  <DrinkPopup drink={selDrink} on:completeOrder={completeOrder} open={openDrinkPopup} on:close={()=> openDrinkPopup=false}></DrinkPopup>
</Page>
<style>
:global(.swiper-featured) {
  margin-top:0px;
  padding-top:0px;
}
.page-content {
  padding-top:0px;
}
.rate-badge {
  line-height: 14px;
  font-size: 13px;
  font-weight: 600;
  color: #ff1973;
  flex-direction: column;
  padding: 0px 10px;
  border-radius: 5px;
  height: 40px;
}

.rate-badge {
  margin-right:10px;
}
.rate-badge img {
  width: 20px;
  height: 20px;
  display: block;
  max-width: 100%;
  
}


.venue-profile {
    width: 75%;
    min-height:120px;
    margin: 0 auto;
    position: relative;
    top: 142px;
    background: #fff;
    color: #000;
    border-radius: 10px;
   box-shadow: 0 12px 10px -10px gray;
   padding-left:10px;
   padding-top:4px;
}
.venue-server {
  width:100%;
    margin: 0 auto;
  margin-bottom: 3px !important;
}
:global(.theme-dark .subnav-venue){
  background: #202020;
 
}
:global(.theme-dark .venue-profile) {
  background: var(--f7-list-bg-color) !important;
  color:#fff !important;
   box-shadow: 0 12px 10px -10px #333 !important;
}
:global(.theme-dark .venue-server .user-infos .user-name) {
  color:#fff;
}
:global(.theme-dark .venue-server .user-infos .user-info) {
  color: #9ACD32;
}
:global(.theme-dark .venue-server .rate-badge) {
  background: #1e224c;
}
.info {
     font-size: 12px;
    color: #7d7d7d;
}
:global(.theme-dark .venue-server .info){
   color: #fff;
}
.venue-info {
   padding-left: 10px;
}
.venue-info h2, .venue-info h1 {
    margin:0;
}



.user-small {
    margin-top:8px;
}
.user-small img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: block;
    max-width: 100%;
}
.venue-content {
  margin-top:100px;
}
:global(.venue-content .block-title) {
    margin-bottom: 5px;
}

:global(.featured-pagination .swiper-pagination-bullet) {
  margin-left: 6px;
}
:global(.theme-dark .fab .fab-text) {
  color: #fff
}
</style>
<script>
 import { f7,f7ready,Tabs,Tab,PageContent,Row,Col,Subnavbar,Popover,Button,NavTitle,NavTitleLarge,FabBackdrop,NavLeft,Icon, Swiper,SwiperSlide,Page,Link,Toolbar,Popup,NavRight,CardFooter,List,ListItem,PhotoBrowser,Navbar, BlockTitle, Block,Card,CardHeader,CardContent,SkeletonBlock,Chip } from 'framework7-svelte';
//import {user2} from '../assets/user2.jpg'
//import {star} from '../assets/star2.png'
//import {beerIcon} from '../assets/icons/beer-icon2.png'
//import {cocktailIcon} from '../assets/icons/cocktail-icon.png'
//import {wineIcon} from '../assets/icons/wine-icon.png'
//import sol from '../assets/solyluna_small.jpg'
//import football from '../assets/football-small.png'
//import hostessIcon from '../assets/icons/hostess-icon.png'
import { onMount, onDestroy,tick } from 'svelte';
//import beer from '../assets/beer.png'
import {searchDrinks,sendOrder} from '../js/core.js'
import DrinkPopup from '../components/popup-drink.svelte'
import NotificationBell from '../components/dts-shared-components/notification/bell.svelte'
import FabBot from '../components/dts-shared-components/chatbot/bot-fab.svelte'
//import hostess from '../assets/girl-drink.png'
import {places,latestCocktails,popularCocktails,featuredCocktails,userInfo,lastScannedVenue,qrScanConfirmed,venueOrder,venueAlcoholList,checkin} from '../js/store'
import {user,openPopup} from '../components/dts-shared-components/auth/store'
import CardImg from '../components/dts-shared-components/card-img-back.svelte'
import IFeature from '../components/dts-shared-components/img-feature.svelte'
import HeaderBack from '../components/dts-shared-components/ui/header-back-img.svelte'
import IconCatButton from '../components/dts-shared-components/ui/icon-tab-button.svelte'
import DrinkGlass from '../components/drink-glass.svelte'
import {firestoreInstance} from '../components/dts-shared-components/firebase/store'
  export let f7route;
  let tableInfo=null;

let suggest=null;
let openDrinkPopup=false;


let venue=null;
let loadingWine=true;

/* Events */



let selDrink;
let showBot=false;
let botButtons=[{label: `"Surprise me!`,value:"Surprise me!",iconMaterial:"chat"},{label: `"Suggest a drink"`,value:"Suggest a drink", iconMaterial:"chat"},{label: `"Search a cocktail"`,value:"Search a cocktail", iconMaterial:"search"}]

let eDuration;
let popular=[1,2,3,4,5],latestC=[1,2,3,4,5];
let loadingitems=[1,2,3,4,5];
let loadingArr=[1,2,3,4,5];
let loadingPopular=true;
let loadingLatest=true;
let placeName;
let userName="";

let featured=[{
  name:'Strawberry Margarita',
  measure:[],
  ingred:[],
  pic:'https://www.thecocktaildb.com/images/media/drink/tqyrpw1439905311.jpg',
  id:'12322',
  isExt:true
},
{
  name:'Moscow Mule',
   measure:[],
  ingred:[],
  pic:'https://www.thecocktaildb.com/images/media/drink/3pylqc1504370988.jpg',
  id:'11009',
  isExt:true
},
{
  name:'Margarita',
   measure:[],
  ingred:[],
  pic:'https://www.thecocktaildb.com/images/media/drink/5noda61589575158.jpg',
  id:'11007',
  isExt:true
}
]


let dynTabData=[
   { id:'tb-beers',name:'Beers', type:'beer'},
  { id:'tb-tequila',name:'Tequila', type:'tequila'},
  { id:'tb-vodka',name:'Vodka', type:'vodka'},
  { id:'tb-gin',name:'Gin', type:'gin'},
   { id:'tb-rum',name:'Rum', type:'rum'},
  { id:'tb-bourb',name:'Bourbon', type:'bourbon'},
  { id:'tb-whiskey',name:'Whiskey', type:'whiskey'},
   { id:'tb-sco',name:'Scotch', type:'scotch'}
]

let hhEnd=add(new Date(),{hours: 2});
let botAction="welcome-silent";

import {formatDistanceToNow,add} from 'date-fns'
function clickFeatured(index){
  selDrink=featured[index];
  f7.popup.open(".drink-detail-popup");
}
function clickDrink(type,index){
  if (type == 'popular')  selDrink=venue.cocktails.popular[index];
  else
   if (type == 'featured')  selDrink=venue.cocktails.popular[index];
  else if (type == 'latest'){
    selDrink=venue.cocktails.latest[index];
  }else{
    if (type.includes('wine')){
      selDrink=venue.wine.full[index];
    }else
      selDrink=venue[type][index];

  }
  let deviceWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
let mobileWidth=500;
if (deviceWidth > 481) mobileWidth=700;

   if (selDrink.internal)
    selDrink.picUrl=`https://img.imageboss.me/justdrinks/width/${mobileWidth}/${selDrink.pic}`
  else
    selDrink.picUrl=`https://img.imageboss.me/cocktails/width/${mobileWidth}/${selDrink.pic}`;
    
  openDrinkPopup=true;

}
function completeOrder(e){
  let order=e.detail;

 /*
  if ($user.isAnonymous){
       f7.dialog.confirm("We need to complete your account setup before ordering (No credit card required)","Setup required",
       function () {
          // OK.
           f7.popup.close();
          openPopup.set(true);

       },function () {

       });
       return;
  }
*/

      window.setTimeout(()=>{
            f7.dialog.close();
            let curOrder=$venueOrder;
            if (!curOrder.closed){
              curOrder.lastActivity=new Date();
              if (!curOrder.requests) curOrder.requests=[];
              
              // How many drinks pending for this user?
              const count = curOrder.requests.filter(item => item.status === 'pending' && item.uid == $user.uid).length;
              if (count == 2)
                f7.dialog.alert("Whoa take it easy! Only 2 pending drink orders allowed per person. Please wait for your other drinks or cancel pending orders.")
              else{
                let name=$user.displayName;
                let request={
                    id: curOrder.id,
                    status: 'pending',
                    descr: order.order,
                    ingred: order.drink.ingred,
                    internal: order.drink.internal ? true : false,
                    drink: order.drink.name,
                    pic: order.drink.pic,
                    user: name,
                    type: order.drink.type ? order.drink.type : "cocktail",
                    drinkId: order.drink.id,
                    uid: $user.uid,
                    created: new Date()
                }
                sendOrder(request)
                .then(()=>{
                   f7.dialog.alert('Request sent! Your drink should be delivered shortly. Please be patient.');
                   f7.tab.show('#view-alerts', '.view-alerts-link', true)
                });
                /*
                curOrder.requests.push({
                    status: 'pending',
                    descr: order.order,
                    internal: order.drink.internal ? true : false,
                    drink: order.drink.name,
                    pic: order.drink.pic,
                    user: name,
                    type: order.drink.type ? order.drink.type : "cocktail",
                    drinkId: order.drink.id,
                    uid: $user.uid,
                    created: new Date()
                });
                */

                //let topic=`${curOrder.ownerId}-${curOrder.created.seconds}`;
                /*
                $firestoreInstance.collection(`activeTables/${venue.id}/tables`).doc($lastScannedVenue.tableNo)
                .set(curOrder,{merge:true})
                .then(()=>{
                  let picUrl="",tnUrl="";
                  if (order.drink.internal){
                      tnUrl="https://img.imageboss.me/justdrinks/width/100/" + order.drink.pic;
                      picUrl="https://img.imageboss.me/justdrinks/width/200/" + order.drink.pic;
                  }else{
                      tnUrl="https://img.imageboss.me/cocktails/width/100/" + order.drink.pic;
                      picUrl="https://img.imageboss.me/cocktails/width/200/" + order.drink.pic;
                  }
                    f7.dialog.alert('Request sent! Your drink should be delivered shortly. Please be patient.');
                      window.setTimeout(()=>{
                        let body=JSON.stringify({topic: topic,title: "We've received your order!",msg:`${name}'s ${order.drink.name} drink is in progress and should be delivered shortly.`,pic: picUrl,icon:tnUrl, ext: !order.drink.internal,id:order.drink.id});
                        
                        const res=	window.fetch(`https://us-central1-fiesta-drinks.cloudfunctions.net/sendNotif`,
                        {method:"POST",headers:{"Content-Type":"application/json"},
                        body: body})
                        res.then((response)=>{
                          if (response.ok)
                            return response.json();
                        })
                        .then((data)=>{
                          console.log("back from sendNotif",data)
                        })
                     },10000)
                })
                .catch(()=>{
                    f7.dialog.alert('Something went wrong while sending your request. Please try again.');
                })
                */
              }
            }else{
              f7.dialog.alert("This order has been closed. Please re-scan QR code to start a new order.")
            }

            /*
            window.setTimeout(()=>{
                  const res=	window.fetch(`https://us-central1-fiesta-drinks.cloudfunctions.net/sendNotif`,
                  {method:"POST",headers:{"Content-Type":"application/json"},
                  body:JSON.stringify({token: $messagingToken,title: "We've received your order!",msg:`We are preparing your ${drink.name}!`,pic: drink.pic,icon:`${drink.pic}/preview`, ext:true,id:drink.id})});
                  res.then((s)=>{
                  })
            },10000)
            */
      },1000)

}
function botButtonClick(e){
  let bindex=e.detail.b;
  suggest=botButtons[bindex].value;
  f7.popup.open(".b-popup");

}



let activeTableUnsubscribe=null;

/*
function setupActiveTableEvent(id){
  console.log(`Watching activeTables/${id}/tables/${$lastScannedVenue.tableNo}`)
  if (activeTableUnsubscribe)
    activeTableUnsubscribe();

  activeTableUnsubscribe=$firestoreInstance.collection(`activeTables/${venue.id}/tables`).doc($lastScannedVenue.tableNo)
      .onSnapshot(async function(doc) {
        if (doc.exists){
          console.log("got new venue order update")
          let data=doc.data();
          let users=[];
          await doc.ref.collection('users')
          .get()
          .then((qs)=>{
            qs.forEach(function(doc) {
              let data=doc.data();
                // doc.data() is never undefined for query doc snapshots
              users.push({ id: doc.id,name: data.name, pic: data.pic});
            });
          data.users=users;
          //      let fi=users.findIndex((u)=>{
          //          return u.id == $user.uid
          //      })
          //if (fi == -1){
            // If current user not found
          //}
          venueOrder.set(data);
          });
          //tableInfo=doc.data();
        }else{
          venueOrder.set(null);
        }
  });
}
*/
function setupBot(){
    // Show bot
    showBot=true;
    /*
    f7.$('.venue-page-content').on('scroll',(e)=>{
        let top=e.srcElement.scrollTop;
        console.log(top)
        if (top > 100){
            showBot=true;
        }
    })
    */
}

function GetVenueBeers(){

}

function getArrayfromQuerySnapshot(qs){
  let list=[];
  qs.forEach((doc)=>{
    list.push(doc);
  })
  return list;
}

function GetVenueWine(){
  loadingWine=true;
  let reds=[];
  let whites=[];
  let sparkling=[];
  let full=[];

    //let locDocRef=$firestoreInstance.collection(`locations/${venue.id}/drinks`);
    let redRef1=$firestoreInstance.collection(`locations/${venue.id}/drinks/wine/cabernet-sauvignon`);
    let redRef2=$firestoreInstance.collection(`locations/${venue.id}/drinks/wine/cabernet-franc`);
    let redRef3=$firestoreInstance.collection(`locations/${venue.id}/drinks/wine/pinot-noir`);
    let redRef4=$firestoreInstance.collection(`locations/${venue.id}/drinks/wine/red-blend`);
    let redRef5=$firestoreInstance.collection(`locations/${venue.id}/drinks/wine/zinfandel`);
    let redRef6=$firestoreInstance.collection(`locations/${venue.id}/drinks/wine/merlot`);
    let redRef7=$firestoreInstance.collection(`locations/${venue.id}/drinks/wine/shiraz`);
    let redRef8=$firestoreInstance.collection(`locations/${venue.id}/drinks/wine/sangiovese`);

    let whiteRef1=$firestoreInstance.collection(`locations/${venue.id}/drinks/wine/pinot-grigio`);
    let whiteRef2=$firestoreInstance.collection(`locations/${venue.id}/drinks/wine/sauvignon-blanc`);
    let whiteRef3=$firestoreInstance.collection(`locations/${venue.id}/drinks/wine/chardonnay`);
    let whiteRef4=$firestoreInstance.collection(`locations/${venue.id}/drinks/wine/riesling`);
    let whiteRef5=$firestoreInstance.collection(`locations/${venue.id}/drinks/wine/muscat`);

    console.log(`locations/${venue.id}/drinks/wine/cabernet`);

   var dbPromises = [];
   dbPromises.push(redRef1.get());
   dbPromises.push(redRef2.get());
   dbPromises.push(redRef3.get());
   dbPromises.push(redRef4.get());
   dbPromises.push(redRef5.get());
   dbPromises.push(redRef6.get());
   dbPromises.push(redRef7.get());
   dbPromises.push(redRef8.get());

   dbPromises.push(whiteRef1.get());
   dbPromises.push(whiteRef2.get());
   dbPromises.push(whiteRef3.get());
   dbPromises.push(whiteRef4.get());
   dbPromises.push(whiteRef5.get());



   return Promise.all(dbPromises)
   .then((resolved) => {
     let red1=resolved[0];
     let red2=resolved[1];
     let red3=resolved[2];
     let red4=resolved[3];
     let red5=resolved[4];
     let red6=resolved[5];
     let red7=resolved[6];
     let red8=resolved[7];
     let wh1=resolved[8];
     let wh2=resolved[9];
     let wh3=resolved[10];
     let wh4=resolved[11];
     let wh5=resolved[12];


     
     let index=0;
     red1.forEach((wine)=>{
       let w=wine.data();
       w.index=index;
        reds.push(w);
       index++;
     });

    red2.forEach((wine)=>{
       let w=wine.data();
       w.index=index;
        reds.push(w);
       index++;
     })

      red3.forEach((wine)=>{
       let w=wine.data();
       w.index=index;
        reds.push(w);
       index++;
     })
      red4.forEach((wine)=>{
       let w=wine.data();
       w.index=index;
        reds.push(w);
       index++;
     })
    red5.forEach((wine)=>{
       let w=wine.data();
       w.index=index;
        reds.push(w);
       index++;
     })
      red6.forEach((wine)=>{
       let w=wine.data();
       w.index=index;
        reds.push(w);
       index++;
     })
        red7.forEach((wine)=>{
       let w=wine.data();
       w.index=index;
        reds.push(w);
       index++;
     })
        red8.forEach((wine)=>{
       let w=wine.data();
       w.index=index;
        reds.push(w);
       index++;
     })
     console.log("REDS",reds)
      wh1.forEach((wine)=>{
       let w=wine.data();
       w.index=index;
        whites.push(w);
       index++;
     })
      wh2.forEach((wine)=>{
       let w=wine.data();
       w.index=index;
        whites.push(w);
       index++;
     })

       wh3.forEach((wine)=>{
       let w=wine.data();
       w.index=index;
        whites.push(w);
       index++;
     })

       wh4.forEach((wine)=>{
       let w=wine.data();
       w.index=index;
        whites.push(w);
       index++;
     })
       wh5.forEach((wine)=>{
       let w=wine.data();
       w.index=index;
        whites.push(w);
       index++;
     })
     //full=full.push.apply(reds,whites);
     console.log(full)
     reds.forEach((w)=>{
       full.push(w);
     })
      whites.forEach((w)=>{
       full.push(w);
     })
      console.log(reds,whites,full)

     let wineList={
          full: full,
          reds: reds,
          whites: whites,
          sparkling: sparkling
      }

        //console.log(cab);
        venue.wine=wineList;

   });


     

 

/*
    let ref=locDocRef.doc('wine')
     ref.get()
    .then((d)=>{
      loadingWine=false;
      if (d.exists) return d.data();
      else return null;
    })
    .then((d)=>{
      if (d){

        // Redo name for display
        let reds=[];
        let whites=[];
        let sparkling=[];
        let index=0;
        d.list.forEach((w)=>{
         // w.name = `${w.name}<br/>${w.style}`
         // w.name = `${w.year} ${w.name}<br/>${w.style} (${w.from})`;
          let type=w.type;
          if (type =='wine-rose' || type == 'wine-pinotnoir' || type == 'wine-cabernet' || type == 'wine-redblend' || type == 'wine-zinfandel' || type == 'wine-syrah'){
            reds.push(w)
          }else
            if (type == 'wine-pinotgrigio' || type == 'wine-sauvignonblanc' || type == 'wine-chardonnay'){
              whites.push(w);
            }else
              if (type == 'wine-sparkling')
                sparkling.push(w);

            w.index=index;
            
            index++;

        })
*/
        // build wine list based on style
        /*
        let cab=d.list.filter((e)=>e.style == "Cabernet");
        let pnoir=d.list.filter((e)=>e.style == "Pinot Noir");
        let pgrigio=d.list.filter((e)=>e.style == "Pinot Grigio");
        let reisling=d.list.filter((e)=>e.style == "Reisling");
        let rose=d.list.filter((e)=>e.style == "Rose");
        let sblanc=d.list.filter((e)=>e.style == "Sauvignon Blanc");
        let chard=d.list.filter((e)=>e.style == "Chardonnay");
        let redblend=d.list.filter((e)=>e.style == "Red Blend");
        let zinf=d.list.filter((e)=>e.style == "Zinfandel");
        let syrah=d.list.filter((e)=>e.style == "Syrah");

        let winetypes=[];
        if (cab) winetypes.push({heading:cab[0].style,'type': cab[0].type,list: cab});

        if (pnoir) winetypes.push({heading:pnoir[0].style,'type': pnoir[0].type,list: pnoir});
        if (pgrigio) winetypes.push({heading:pgrigio[0].style,'type': pgrigio[0].type,list: pgrigio});
        if (rose) winetypes.push({heading:rose[0].style,'type': rose[0].type,list: rose});
        */

       

}
function GetVenueShots(){
    let locDocRef=$firestoreInstance.collection(`locations/${venue.id}/drinks`);
    let ref=locDocRef.doc('shots')
     ref.get()
    .then((d)=>{
      if (d.exists) return d.data();
      else return null;
    })
    .then((d)=>{
      if (d)
        venue.shots=d.list;
    });

}

function GetDrinks(type){

    let locDocRef=$firestoreInstance.collection(`drinks`);
    let dref=locDocRef.doc(type)
     dref.get()
    .then((d)=>{
      if (d.exists) return d.data();
      else return null;
    })
    .then((d)=>{
      if (d){
        console.log(`${type} drinks`,d)
        venue[type]=d.list;
        //console.log(d.list)
      }
    });
}

function loadAlcoholList(){
	//loading=false;
	let loadAgain=false;
  let brands={};

	let alcoholList=$venueAlcoholList
	if (alcoholList){
    console.log("alc list",alcoholList)
	  if (alcoholList.lid != venue.id) loadAgain=true;
	  else
	   venue.brands=alcoholList;
	}else{
	  loadAgain=true;
	}
   if (loadAgain){
	 let locDocRef=$firestoreInstance.collection(`locations/${venue.id}/alcohol`);
	 let tref=locDocRef.doc('tequila')
	 let vref=locDocRef.doc('vodka')
	 let gref=locDocRef.doc('gin')
	 let rref=locDocRef.doc('rum')
   let sref=locDocRef.doc('scotch')
  let wref=locDocRef.doc('whiskey')
    let bref=locDocRef.doc('bourbon')
	 brands.lid=venue.id;
	 tref.get()
	 .then((d)=>{
	   if (d.exists) return d.data();
	   else return null;
	 })
	 .then((d)=>{
	   brands.tequila=d;
	 })
	 vref.get()
	 .then((d)=>{
	   if (d.exists) return d.data();
	   else return null;
	 })
	 .then((d)=>{
	   brands.vodka=d;
	 })
	 gref.get()
	 .then((d)=>{
	   if (d.exists) return d.data();
	   else return null;
	 })
	 .then((d)=>{
	   brands.gin=d;
	 })
   rref.get()
	 .then((d)=>{
	   if (d.exists) return d.data();
	   else return null;
	 })
	 .then((d)=>{
	   brands.rum=d;
 
   })
   sref.get()
	 .then((d)=>{
	   if (d.exists) return d.data();
	   else return null;
	 })
	 .then((d)=>{
	   brands.scotch=d;
 
   })
   bref.get()
	 .then((d)=>{
	   if (d.exists) return d.data();
	   else return null;
	 })
	 .then((d)=>{
	   brands.bourbon=d;
 
	 })
   wref.get()
	 .then((d)=>{
	   if (d.exists) return d.data();
	   else return null;
	 })
	 .then((d)=>{
	   brands.whiskey=d;
     venueAlcoholList.set(brands);
     venue.brands=brands;
	 })
   }
 
 }
 

function GetTequila(){
  GetDrinks('tequila')
}
function GetVodka(){
  GetDrinks('vodka')
}
function GetRum(){
  GetDrinks('rum')
}
function GetGin(){
  GetDrinks('gin')
}
function GetWhiskey(){
  GetDrinks('gin')
}

function loadData(place){
  let vt={};

  vt.name=place.data.name;
  vt.tagLine=place.data.tagLine;

  vt.dataLoaded=false;
  vt.id=place.id
  vt.brands={};
  vt.wine=[];

  venue=vt;

  //vt.server = {
  //  name: 'Jane Queen',
  //  title: 'Server/Super drink runner',
  //  pic: user2,
  //  rating:'4.6'
  //}
  vt.cocktails={};

  //setTimeout(()=>{
  //f7.preloader.show('green')
//
 // },100)

  if ($featuredCocktails == null){
     searchDrinks('popular')
    .then((drinks)=>{
        if (drinks){
           featuredCocktails.set(drinks);
           venue.cocktails.featured=drinks
        }
     });
  }else{
    venue.cocktails.featured=$featuredCocktails;
  }
  if ($popularCocktails == null){
    searchDrinks('popular')
    .then((drinks)=>{
            if (drinks){
                popularCocktails.set(drinks);
                venue.cocktails.popular=drinks;
            }
    })
    .catch((e)=>{
//   f7.dialog.alert("We are having some issue trying to retrieve drinks. Please check again in a few minutes.");
  //   f7.preloader.hide();
     })
  }else{
    venue.cocktails.popular=$popularCocktails;
  }

  if ($latestCocktails == null){
    searchDrinks('latest')
    .then((drinks)=>{
            if (drinks){
               latestCocktails.set(drinks);
              venue.cocktails.latest=drinks;
            }

    })
    .catch((e)=>{
        console.log(e)
    });

  }else{
    venue.cocktails.latest=$latestCocktails;
  }

  loadAlcoholList();


}

function checkIn(){
  // track initial user activity
  let date=new Date();

  let tableNo=null;

  if ($lastScannedVenue && ($lastScannedVenue.lid == venue.id)){
    tableNo=$lastScannedVenue.tableNo;
  }

  let dbRef=$firestoreInstance.collection('checkins')
  .where('uid','==',$user.uid)
  .where('lid','==',venue.id)
  .get()
  .then((qs)=>{
    if (qs.empty){
      let obj={       created: date,
            lid: venue.id,
            uid: $user.uid,
            name: $user.displayName,
            count:1,
            tableNo: tableNo
      }
      $firestoreInstance.collection("checkins").add(obj
      )
      .then((doc)=>{
          obj.id=doc.id;
          checkin.set(obj)
      })
    }else{
         qs.forEach((doc)=>{
            let obj=doc.data();
            obj.count++;
            obj.id=doc.id;
            obj.lastUpdated=date;
            obj.name=$user.displayName;
            obj.tableNo=tableNo;
            dbRef=$firestoreInstance.collection('checkins').doc(doc.id).set(obj,{merge:true})
            checkin.set(obj)
        })
    }
  });

}

 function onPageInit(){
    let id = f7route.params.id;
    let place=$places[id];
    loadData(place);

    checkIn();

    window.setTimeout(()=>
    {
      if ($venueOrder)
        if (!$user.displayName){
            f7.dialog.prompt('The name is used to personalize your order','What is your name?',(name)=>{
                $user.updateProfile({
                    displayName: name,
                }).then(function(r) {
                  
                });
            })
        }
    },60000)

    // Get Active table event
    if ($qrScanConfirmed){
    
    }
    setupBot();

  }


onMount(() => {
    console.log("on mount")
    f7ready(() => {
      
      /*
     tick().then(()=>{
         console.log("user",$user)
          if ($user){
            userName=$user.displayName
          }
          showBot=true;
        
      })
      */

      /*
      f7.$('.page-content').on('scroll',(e)=>{
        let top=e.srcElement.scrollTop;
        if (top > 33){
           //f7.$('.venue-navbar .link.icon-only i').removeClass("dark")
          f7.$('.navbar.venue-nav').removeClass("navbar-large");
        }else{
          f7.$('.navbar.venue-nav').addClass("navbar-large");
        }
    })
*/

      eDuration=formatDistanceToNow(hhEnd);

      
    
    });

});

</script>