<Page noNavbar noToolbar noSwipeback name="walkthrough" on:pageInit={onPageInit}>
    <div class="walkthrough">

        <form name="setup" method="POST" action="#" enctype="multipart/form-data" style="height:100%">
        <div class="swiper-container walkthrough-container" >
            <div class="parallax-bg" style="background-image:url('https://img.imageboss.me/justdrinks/width/800/guy-at-bar2.jpg')" data-swiper-parallax="-34%">
                <div class="content"><div class="fade-out"></div></div>
            </div>
            <div class="swiper-wrapper walkthrough-wrapper">
                 
                <div class="swiper-slide walkthrough-slide">
                    <div class="slide-title">Welcome to Just Drinks!</div>
                    <div class="slide-content-wrapper">
                        <div class="slide-media">
                        </div>
                        <div class="slide-text">The drink menu has evolved.</div>
                        <div class="slide-actions">
                            <button class="button button-big button-fill button-round color-green" data-i18n="login" on:click={openNameQuery}>Continue</button><br/>
                            <button class="button button-big button-round" data-i18n="next" on:click={login}>I already have an account</button>
                        </div>
                    </div>
                </div>

                 <div class="swiper-slide walkthrough-slide">
                        <div class="slide-title">We just need a little info.</div>
                        <div class="slide-media">
                            <div class="list no-hairlines no-hairlines-between">
                                    <ul class="item-container">
                                        <li>
                                            <div class="item-content item-input">
                                                <div class="item-inner">
                                                    <div class="item-title item-label">What is your name?</div>
                                                    <div class="item-input-wrap">
                                                        <input type="text" bind:this={nameBox} name="name" required bind:value={name} />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                            </div>
                        </div>
                        <div class="slide-actions">
                            <button class="button button-big button-fill button-round" on:click={validateName}>Next</button>
                        </div>
                    </div>

<!--
                <div class="swiper-slide walkthrough-slide">
                    <div class="slide-title">Choose your Language</div>
                    <div class="slide-media">
                        <ul class="flags-wrapper">
                            <li>
                                <label>
                                    <input type="radio" name="language" value="en" data-dir="ltr" />
                                    <img src={flagUK} alt="" />
                                    <span data-i18n="english">English</span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="radio" name="language" value="hi" data-dir="ltr" />
                                    <img src={flagUK} alt="" />
                                    <span data-i18n="hindi">Hindi</span>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input type="radio" name="language" value="ar" data-dir="rtl" />
                                    <img src={flagUK} alt="" />
                                    <span data-i18n="arabic">Arabic</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="slide-actions">
                        <button class="button button-big button-fill button-round" data-i18n="next" on:click={openSlideTheme}>Next</button>
                    </div>
                </div>-->
                   

                    <div class="swiper-slide walkthrough-slide">
                        <div class="slide-title">Let us help you find drinks.</div>
                        <div class="slide-media">
                            	<div class="list no-hairlines no-hairlines-between">
								<ul class="item-container">
									<li>
										<div class="item-content item-input no-padding">
											<div class="item-inner no-padding">
												<div class="item-title item-label">Select favorite alcohol</div>
												<div class="block no-margin" style="padding: 8px;">
                                                     {#each alcohol as a}
                                                        <label class="chip-selectable">
                                                            <input type="checkbox" name="alcohol[]" bind:group={alcoholList} value={a} />
                                                            <div class="chip chip-outline color-gray">
                                                                <div class="chip-label">{a}</div>
                                                            </div>
                                                        </label>
                                                     {/each}
                                                    <div class="ctr" style="padding-top:10px">
                                                     <label class="chip-selectable">
                                                            <input type="checkbox" name="alcohol[]" bind:group={alcoholList} value="I don't drink" />
                                                            <div class="chip chip-outline color-gray">
                                                                <div class="chip-label">I don't drink</div>
                                                            </div>
                                                        </label>
                                                    </div>
												</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
                        </div>
                        <div class="slide-text"></div>
                        <div class="slide-actions">
                            <button class="button button-big button-fill button-round" on:click={checkAlcohol}>Next</button>
                        </div>
                    </div>

                    <div class="swiper-slide walkthrough-slide">
                        <div class="slide-title">Explore Just Drinks!</div>
                        <div class="slide-media">
                        </div>
                        <div class="slide-text">You're all set, {name}.</div>
                        <div class="slide-actions">
                            <button class="button button-big button-fill button-round" on:click={loadNextScreen}>Browse drinks</button>
                        </div>
                    </div>

            </div>

            <div class="swiper-pagination walkthrough-pagination"></div>

        </div>
        </form>
	</div>
</Page>
<style>
.parallax-bg {
    position: absolute;
  left: 0;
  top: 0;
  width: 160%;
  height: 60%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}
.parallax-bg .fade-out {
    position: absolute;
    left:0px;
    bottom:0px;
    width:100%;
      height:25%;
  
          background: -webkit-linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
) !important; 
background: -moz-linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
)  !important;
background: -o-linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
)  !important;
background: linear-gradient( rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%) !important;
background: -ms-linear-gradient( rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%) !important;
}
.walkthrough {
        height: 100%;
        background-color: #000;
	}

	.walkthrough-container {
		height: 100%;
	}

	.walkthrough-wrapper {
		height: 100%; 
	}

	.walkthrough-slide {
		align-items: stretch;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: flex-start;
        text-align: center;
        height:calc(100%-40px);

	}

	:global(.ios .walkthrough-slide) {
		padding: 35px 15px;
	}

	:global(.md .walkthrough-slide) {
		padding: 32px 16px;
	}

	.walkthrough-slide .slide-title {
        font-size: 26px;
        color:#fff;
	}

	.ios .walkthrough-slide .slide-media {
        margin: 120px 0;
	}

	.md .walkthrough-slide .slide-media {
		margin: 120px 0;
	}
  
	.walkthrough-slide .slide-text {
        font-size: 1.2em;   
        color:#ffffff;
            
	}

	.ios .walkthrough-slide .slide-text {
		margin-bottom: 35px;
	}

	.md .walkthrough-slide .slide-text {
		margin-bottom: 32px;
	}

	.walkthrough-slide .slide-actions {
        position: absolute;
        bottom:45px;
        height: 70px;
        width:calc(100% - 32px);
        vertical-align: middle;
	}

	.walkthrough-actions {
		bottom: 0;
		box-sizing: border-box;
		left: 0;
		position: absolute;
		width: 100%;
		z-index: 50;
	}

	:global(.ios .walkthrough-actions) {
		margin-bottom: 35px;
		padding: 0 15px;
	}

	:global(.md .walkthrough-actions) {
		margin-bottom: 32px;
		padding: 0 16px;
	}

	.walkthrough-pagination {
		bottom: 80px;
	}

	

	.flags-wrapper li label input {
		display: none;
	}

	
	

	.colors-wrapper li label {
		display: block;
	}

	.colors-wrapper li label input {
		display: none;
	}
    
    .item-label {
		color: var(--oc-color-gray-4) !important;
		font-size: 26px;
		font-weight: bold;
		line-height: normal;
	}

	:global(.item-label.input-valid) {
		color: var(--oc-color-green-8) !important;
	}

	:global(.item-label.input-invalid) {
		color: var(--oc-color-red-8) !important;
	}

	:global(.item-input-focused .item-label) {
		color: var(--oc-color-gray-4) !important;
	}

	:global(.item-input-focused .item-label.input-valid) {
		color: var(--oc-color-green-8) !important;
	}

	:global(.item-input-focused .item-label.input-invalid) {
		color: var(--oc-color-red-8) !important;
    }
    
    :global(.item-input-wrap .error) {
        color: var(--oc-color-red-8) !important;
    }

	:global(.item-input-wrap) {
		margin-top: 24px !important;
	}

	:global(.item-input-focused .item-input-wrap:after) {
		display: none;
	}

	:global(.item-input-with-value .item-input-wrap:after) {
		display: none;
	}
	.list input {
		color:  var(--oc-color-violet-8) !important;
		font-size: 24px !important;
		font-weight: bold;
		text-align: center;
    }
    .walkthrough-slide .item-container {
        background-color: transparent;
    }
</style>
<script>
import {tick} from 'svelte'
  import {f7ready, Page, Navbar, Block,Button,f7 } from 'framework7-svelte';
  import rocket from '../assets/img/rocket.svg'
  import flagUK from '../assets/img/flag-united-kingdom.svg'
  import {Utili18n} from '../js/core'
 import {oldUser,userInfo} from '../js/store'
 import {firebaseAuthLoaded,firestoreInstance} from '../components/dts-shared-components/firebase/store'
 import {user,openPopup} from '../components/dts-shared-components/auth/store'
import {requestPermission} from '../components/dts-shared-components/location/store'
 import jQuery from "jquery"
 import validate from 'jquery-validation'
let nameBox;

  let walkthrough=null;
  let formValidator=null;
  let alcohol = [
	"Tequila","Vodka","Rum","Gin","Whiskey","Scotch","Bourbon","Red Wine","White Wine","Lite Beer","Dark Beer","Amber/IPA Beer"
]

 let alcoholList=[];
 let name="";
function login(e){
     e.preventDefault();
     openPopup.set(true)

}
  function GetUserLocation(){
      // Signal location component
       requestPermission.set(true);
  }
  function openSlideLanguage(){
	  //  walkthrough.slideNext();
//		f7.$('[name=language]').on('change', function() {
//		    Utili18n.setLanguage({lang: this.value, dir: this.dataset.dir});
 //       });
        
  }
function openNameQuery(e){
    e.preventDefault();

    GetUserLocation();

    setTimeout(()=>{
        nameBox.focus();
    },300)
    walkthrough.slideNext();

    
  }
  function openSlideTheme(){
	walkthrough.slideNext();
	//f7.$('[name=theme-color]').on('change', function() {
	//				appColor.set(this.value);//
	//			});
  }
  function openSlideExplore(){
        walkthrough.slideNext();
  }
  function loadNextScreen(e){
      e.preventDefault();

      oldUser.set(true);

      // Save user info
      
      let ui={
          alc: alcoholList,
          themeDark: true,
          created: firebase.firestore.Timestamp.now()
      }
      userInfo.set(ui);


      initUserDB(ui,function(){
        f7.views.current.router.navigate('/home/');

      });
  }

  function initUserDB(ui,callback){
     if ($firebaseAuthLoaded){
          firebase.auth().signInAnonymously()
          .then(()=>{
              var user = firebase.auth().currentUser;
              user.updateProfile({
                    displayName: name,
                    photoURL: `https://api.adorable.io/avatars/128/${user.uid}.png`
                    }).then(function() {
                    // Update successful.
                     // Save user info to fb store.
                    let userRef=$firestoreInstance.collection(`users`);
                    userRef.doc(user.uid)
                        .set(ui,{merge:true});
                
                    })
                    .then(()=>{
                        callback();
                    })
                    .catch(function(error) {
                        f7.dialog.alert(error)
                    // An error happened.
                    });

          })
          .catch(function(error) {
               f7.dialog.alert(error);
                // Handle Errors here.
          //      var errorCode = error.code;
           //     var errorMessage = error.message;
          });
     }
  }
  function loadNextSlide(e){
      e.preventDefault();
      walkthrough.slideNext();
  }

  function checkmarkCurrentLanguage(){
        var currentLanguage = Utili18n.getLanguage();
        f7.$('[name=language][value=' + currentLanguage.lang + ']').prop('checked', true);
  }
  function checkmarkCurrentTheme(){
        var currentThemeColor = f7.utils.theme.getColor();
        f7.$('[name=theme-color][value=' + currentThemeColor + ']').prop('checked', true);
  }
  function initializeWalkthrough(){
        walkthrough = f7.swiper.create('.walkthrough-container', {
                    allowTouchMove: false,
                    parallax:true,
					pagination: {
						el: '.walkthrough-pagination',
						type: 'progressbar'
					}
        });
        //walkthrough.on('slideChange', function () {
        //     f7.$('input[name=name]').focus();
        //});
  }

 function validateName(e) {
      e.preventDefault();
        if (formValidator.check('[name=name]')) {
            f7.$('input[name=name]').parents('.item-inner').find('.item-label').removeClass('input-invalid').addClass('input-valid');
            walkthrough.slideNext();
        }
        else {
            f7.$('input[name=name]').parents('.item-inner').find('.item-label').removeClass('input-valid').addClass('input-invalid');
            f7.$('input[name=name]').focus();
        }
  }
  function checkAlcohol(e){
       e.preventDefault();

       	if (f7.$("input[name='alcohol[]']:checked").length > 0) {
			f7.$("input[name='alcohol[]']").parents('.item-inner').find('.item-label').removeClass('input-invalid').addClass('input-valid');
            //self.saveProfile();
            walkthrough.slideNext();
		}
		else {
			f7.$("input[name='alcohol[]']").parents('.item-inner').find('.item-label').removeClass('input-valid').addClass('input-invalid');
		}
       
  }

  function initializeFormValidator(){
      
      formValidator = jQuery('form[name=setup]').validate({
					rules: {
						name: {
							required: true
						},
						'alcohol[]': {
						    required: true,
						    minlength: 1
						}
					}
      });
  }

  function onPageInit(){
      //checkmarkCurrentLanguage();
      checkmarkCurrentTheme();
      initializeWalkthrough();
      initializeFormValidator();
  }


</script>