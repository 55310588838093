import { writable} from 'svelte/store';
import { writable as pwritable } from 'svelte-persistent-store/dist/local';

export const notifications=writable([]);
export const firebaseMessagingLoaded=writable(false);
export const messaging=writable(null);
export const messagingToken=writable(null);
export const gotNavPermission=pwritable('NavPerm',false);

export const requestPermission=writable(false);
export const updateToken=writable(false);

