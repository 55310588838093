
<script>
import { onMount,onDestroy, createEventDispatcher} from 'svelte';
import {f7} from 'framework7-svelte'

import {firebaseMessagingLoaded,messaging,updateToken,messagingToken} from './store'
import {firebaseLoaded} from '../firebase/store'

export let messageKey;
export let notifMsg="We would like to send you notifications for the app. Would you like to enable notifications now?";

const dispatch = createEventDispatcher();
$: if ($firebaseLoaded){
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://www.gstatic.com/firebasejs/7.18.0/firebase-messaging.js";
  script.onload=onFirebaseMessagingLoaded;
  document.body.appendChild(script);
}
onMount(() => {
    console.log('--- notification component mounted --')
     
});

function getToken(){
    if ($messaging)
        $messaging.getToken()
        .then((token)=>{
            if (token){
                //saveNotifStatus(token)
                messagingToken.set(token)
            }else{
                messagingToken.set(null);
                f7.dialog.confirm(notifMsg,requestPermission, cancelNotif);
                //"We would like to send you notifications on drink orders when you are away from the app. Would you like to enable notifications now?", requestPermission, cancelNotif)
            }
        })

    function requestPermission(){
         $messaging.requestPermission()
                .then(()=>{
                    return $messaging.getToken();
                })
                 .then((newtoken)=>{
                    //saveNotifStatus(newtoken)
                    messagingToken.set(newtoken)
                 });
    }
    function cancelNotif(){

    }

}

$: if ($updateToken){
    updateToken.set(false);

    getToken();
}

function onFirebaseMessagingLoaded(){
    console.log("-- Firebase Messaging loaded")
    firebaseMessagingLoaded.set(true);
    let fbmsg=firebase.messaging()
    messaging.set(fbmsg);

    if (messageKey)
        fbmsg.usePublicVapidKey(messageKey);


    //fbmsg.onMessage((payload) => {
    //    f7.dialog.alert(JSON.stringify(payload));
//
  //      if (payload.type == 'notif-click')
   //         dispatch('onMessage',payload)
  // ...
    //});
    
}

</script>