<div class="header-back-wrapper" style="--header-min-height:{minHeight};">
    <div class="header-background" class:zoom={zoom} style="--header-img: url({src}); --header-corner-radius: {cornerRadius}">
        <div class="header-back-content">
            <div class="heading-content">
                <slot name="headingContent">Test slot heading content</slot>
            </div>
        </div>
    </div>
</div>
<style>
.header-back-wrapper {
    height: var(--header-min-height,300px);
    width:100%;
    position:absolute;
    top: 0px;
    left:0px;
}
.header-background {
    height:100%;
    width:100%;
    background-image: var(--header-img);
    background-size: cover;
    background-position:center center;
    background-repeat: no-repeat;
    object-fit: cover;
    transform:scale(1);
    transition:scale 4s ease-in;
    border-bottom-left-radius: var(--header-corner-radius);
    border-bottom-right-radius: var(--header-corner-radius);
}
.header-back-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}
.header-background.zoom {
    transform:scale(1.5); 
    transition:all 4s ease-in;

}
div.heading-content {
    color:#fff;
    margin: 10px;
}
</style>
<script>
export let minHeight="255px";
export let src="";
export let cornerRadius="0px";
export let zoom=false;

</script>