import { writable} from 'svelte/store';
import { writable as pwritable } from 'svelte-persistent-store/dist/local';
import { expwritable } from './expstore.js';

export const user = writable(null);
function createLoginStatus() {
	// Dont Assume user is logged in until app code determimes status.
    const { subscribe, set, update } = writable(null);
    
    return {
		subscribe,
		logoff: () => update(() => {return false}),
		login: () => update(() => { return true}),
	};
}


//export function 

export const authInit=writable(false);
export const authChanged=writable(false);
export const loginStatus = createLoginStatus();
export const loginRequired=writable(false);

export const openPopup=writable(false)
export const gotEmailAuthLink=writable(false);
export const savedEmail=pwritable('savedEmail','') 

// Token clears every hour
export const userToken=pwritable('authToken',null)  
