<Page name="featured">
  <Navbar title="Featured Cocktails" backLink="Back" />
  <List mediaList id="featured-list">
    {#each drinks as d,index (index)}
     <ListItem title={d.name} link="#" after="$7" badgeColor="green">
      <div slot="media" class="i">
        <img src={d.pic} alt="" width="100px" />
      </div>
      <div slot="subtitle">House $7/Top Shelf $10+</div>
      <div slot="text">This drink is the most popular! Made with our house tequila Casa Amigos this will
      surely hit the spot after a long day. 
      </div>
    </ListItem>
    {/each}
     <ListItem title="Brandy Alexander" link="#" after="$7" badgeColor="green">
      <div slot="media" class="i">
        <img src="https:\/\/www.thecocktaildb.com\/images\/media\/drink\/tvqxvr1483387746.jpg" alt="" width="100px" />
      </div>
      <div slot="subtitle">House $7/Top Shelf $10+</div>
      <div slot="text">This drink is the most popular! Made with our house tequila Casa Amigos this will
      surely hit the spot after a long day. 
      </div>
    </ListItem>
     <ListItem title="Martinez Cocktail" link="#" after="$10" badgeColor="green">
      <div slot="media" class="i">
        <img src="https:\/\/www.thecocktaildb.com\/images\/media\/drink\/wwxwvr1439906452.jpg" alt="" width="100px" />
      </div>
      <div slot="subtitle">House $10/Top Shelf $12+</div>
      <div slot="text">This drink is the most popular! Made with our house tequila Casa Amigos this will
      surely hit the spot after a long day. 
      </div>
    </ListItem>
     <ListItem title="A piece of ass" link="#" after="$8" badgeColor="green">
      <div slot="media" class="i">
        <img src="https:\/\/www.thecocktaildb.com\/images\/media\/drink\/tqxyxx1472719737.jpg" alt="" width="100px" />
      </div>
      <div slot="subtitle">House $7/Top Shelf $10+</div>
      <div slot="text">This drink is the most popular! Made with our house tequila Casa Amigos this will
      surely hit the spot after a long day. 
      </div>
    </ListItem>
    <ListItem title="Tom Collins" link="#" after="$8" badgeColor="green">
      <div slot="media" class="i">
        <img src="https:\/\/www.thecocktaildb.com\/images\/media\/drink\/qystvv1439907682.jpg" alt="" width="100px" />
      </div>
      <div slot="subtitle">House $7/Top Shelf $10+</div>
      <div slot="text">This drink is the most popular! Made with our house tequila Casa Amigos this will
      surely hit the spot after a long day. 
      </div>
    </ListItem>
  </List>
</Page>
<style>

</style>
<script>
 import { f7,f7ready, Page,Link, List,ListItem,Popup,Row,Col,PhotoBrowser,Navbar, BlockTitle, Block,Card,CardHeader,CardContent,SkeletonBlock,Chip } from 'framework7-svelte';
let drinks=[];

f7ready(() => {
    searchDrinks('featuredDrinks').then((list)=>{
        drinks=list;
    })

});

function searchDrinks(type){
      console.log(`https://us-central1-fiesta-drinks.cloudfunctions.net/${type}`)
    return window.fetch(`https://us-central1-fiesta-drinks.cloudfunctions.net/${type}`)
    .then(function(response) {
        return response.json()
    }).then(function(json) {
        console.log(json)
        return json;
    }).catch(function(ex) {
        console.log("error",ex)
    })
 }
 
</script>
 
