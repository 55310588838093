<Page name="addfriend" class="stretch-content" onPageInit={onPageInit} noToolbar>
<Navbar title="Add friend to table" backLink></Navbar>
<Block>
    <img alt={f7.name} src="https://img.imageboss.me/justdrinks/width/100/logo_transparent_background.png" width="100" />

</Block>

</Page>
<script>
  import { f7,f7ready,Page, Navbar, Block, BlockTitle,Button } from 'framework7-svelte';
  function onPageInit(){

  }
</script>