<Page noNavbar noToolbar class="page-theme-color" noSwipeback name="splash" onPageInit={onPageInit}>
	<Block class="content-align">
		<div class="item text-align-center">
			<img width="250" src={logo} alt={appName}  />
			<p>The super-charged contactless drink menu.</p>
			<div class="splash-preloader">
				<span class="preloader preloader-yellow"></span>
			</div>
		</div>
		
	</Block>
	
</Page>
<style>
p {
	font-size: 17px;
}
.splash {
		align-items: stretch;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-around;
		overflow: hidden;
		text-align: center;
}
  .ios .splash {
		padding: 0 15px;
	}

	.md .splash {
		padding: 0 16px;
	}

	.ios .splash-media {
		margin: 35px 0;
	}

	.md .splash-media {
		margin: 32px 0;
	}

	.splash-media {
		height:400px;
	}

	.splash-media .logo path {
		fill-opacity: 0;
		opacity: 0;
		stroke-width: 5px;
		transition: fill-opacity 1s;
	}

	.splash-media .logo.animation-begin path {
		opacity: 1;
	}

	.splash-media .logo.animation-complete path {
		fill-opacity: 1;
		stroke-width: 0;
	}

	.splash-preloader {
		opacity: 0;
		transition: opacity 1s;
	}

	.ios .splash-preloader {
		margin: 35px 0;
	}

	.md .splash-preloader {
		margin: 32px 0;
	}

	.splash-preloader .preloader {
		height: 32px;
		width: 32px;
	}
</style>
<script>
  import { onMount } from 'svelte';
  import {f7,f7ready, Page, Navbar, Block,Button } from 'framework7-svelte';
import { loginStatus,authChanged,openPopup } from '../components/dts-shared-components/auth/store'
import {place,locInitialized,hideMainToolbar,oldUser} from '../js/store'
let appName="";
let logo;

import {firestoreLoaded} from '../components/dts-shared-components/firebase/store'
//$: {
//	if ($loginStatus != null){
//		f7.views.main.router.navigate({ name: 'home' });
//	}
//}
/*
$: if ($locInitialized){
 f7.views.main.router.navigate({ name: 'home' });
}
window.setTimeout(()=> {
    if (!$gotPosition){
       f7.views.main.router.navigate({ name: 'loc' });
    }
},700)
*/
function onPageInit(){
	appName=f7.name;
	logo=f7.data.logo;

	showPreloader();
	hideToolbar();
	window.setTimeout(()=>{
		f7.views.main.router.navigate({ name: 'home' });
		//console.log("splash test")
	},1500)

	// Immediately try to go to home page (router will delay waiting for all resources to load)
	//f7.views.current.router.navigate('/home/');
	

}
onMount(() => {
    f7ready(()=>{
    });
});
function hideToolbar(){
  	setTimeout(function() {
				f7.$('.toolbar').addClass('toolbar-hidden');
		});
}
function showPreloader(){
  	setTimeout(function() {
				f7.$('.splash-preloader').css('opacity', 1);
		}, 300);
}

</script>