<Page>
  <Navbar title="Settings" />
  <div class="block-title" data-i18n="account">Account</div>
			<div class="list inset">
				<ul>
					<li>
						<a href="/profile/" class="item-link">
							<div class="item-content">
								<div class="item-media">
									<i class="fa-stack">
										<span class="fas fa-square fa-stack-2x text-color-blue ios-only"></span>
										<span class="fas fa-circle fa-stack-2x text-color-blue md-only"></span>
										<span class="fas fa-user fa-stack-1x fa-inverse"></span>
									</i>
								</div>
								<div class="item-inner">
									<div class="item-title" data-i18n="profile">Profile</div>
								</div>
							</div>
						</a>
					</li>
				</ul>
			</div>
			<!--
			<div class="block-title">Connected Accounts</div>
			<div class="list inset">
				<ul>
					<li>
						<div class="item-content">
							<div class="item-media">
								<i class="fa-stack">
									<span class="fas fa-square fa-stack-2x brand-color-facebook ios-only"></span>
									<span class="fas fa-circle fa-stack-2x brand-color-facebook md-only"></span>
									<span class="fab fa-facebook-f fa-stack-1x fa-inverse"></span>
								</i>
							</div>
							<div class="item-inner">
								<div class="item-title">Facebook</div>
								<div class="item-after">
									<label class="toggle toggle-init">
										<input type="checkbox" />
										<span class="toggle-icon"></span>
									</label>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div class="item-content">
							<div class="item-media">
								<i class="fa-stack">
									<span class="fas fa-square fa-stack-2x brand-color-google ios-only"></span>
									<span class="fas fa-circle fa-stack-2x brand-color-google md-only"></span>
									<span class="fab fa-google fa-stack-1x fa-inverse"></span>
								</i>
							</div>
							<div class="item-inner">
								<div class="item-title">Google</div>
								<div class="item-after">
									<label class="toggle toggle-init">
										<input type="checkbox" checked />
										<span class="toggle-icon"></span>
									</label>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
			-->
			<div class="block-title" data-i18n="app-title">{f7.name}</div>
			<div class="list inset">
				<ul>
					<li>
						<div class="item-content">
							<div class="item-media">
								<i class="fa-stack">
									<span class="fas fa-square fa-stack-2x text-color-blue ios-only"></span>
									<span class="fas fa-circle fa-stack-2x text-color-blue md-only"></span>
									<span class="fas fa-info fa-stack-1x fa-inverse"></span>
								</i>
							</div>
							<div class="item-inner">
								<div class="item-title" data-i18n="version">Version</div>
								<div class="item-after">1.1.008</div>
							</div>
						</div>
					</li>
					<!--
					<li>
						<a href="#rate" class="item-link">
							<div class="item-content">
								<div class="item-media">
									<i class="fa-stack">
										<span class="fas fa-square fa-stack-2x text-color-yellow ios-only"></span>
										<span class="fas fa-circle fa-stack-2x text-color-yellow md-only"></span>
										<span class="fas fa-star fa-stack-1x fa-inverse"></span>
									</i>
								</div>
								<div class="item-inner">
									<div class="item-title" data-i18n="rate-app">Rate App</div>
								</div>
							</div>
						</a>
					</li>
					<li>
						<a href="#share" class="item-link">
							<div class="item-content">
								<div class="item-media">
									<i class="fa-stack">
										<span class="fas fa-square fa-stack-2x text-color-green ios-only"></span>
										<span class="fas fa-circle fa-stack-2x text-color-green md-only"></span>
										<span class="fas fa-share-alt fa-stack-1x fa-inverse"></span>
									</i>
								</div>
								<div class="item-inner">
									<div class="item-title" data-i18n="share-app">Share App</div>
								</div>
							</div>
						</a>
					</li>
					<li>
						<a href="/feedback/" class="item-link">
							<div class="item-content">
								<div class="item-media">
									<i class="fa-stack">
										<span class="fas fa-square fa-stack-2x text-color-pink ios-only"></span>
										<span class="fas fa-circle fa-stack-2x text-color-pink md-only"></span>
										<span class="fas fa-comment-dots fa-stack-1x fa-inverse"></span>
									</i>
								</div>
								<div class="item-inner">
									<div class="item-title">Leave Feedback</div>
								</div>
							</div>
						</a>
					</li>
					-->
				</ul>
			</div>

			<div class="block-title" data-i18n="social">Social</div>
			<div class="list inset">
				<ul>
					<li>
						<a href="https://facebook.com/justdrinksapp" target="_blank" class="item-link external">
							<div class="item-content">
								<div class="item-media">
									<i class="fa-stack">
										<span class="fas fa-square fa-stack-2x brand-color-facebook ios-only"></span>
										<span class="fas fa-circle fa-stack-2x brand-color-facebook md-only"></span>
										<span class="fab fa-facebook-f fa-stack-1x fa-inverse"></span>
									</i>
								</div>
								<div class="item-inner">
									<div class="item-title">Like us on Facebook</div>
								</div>
							</div>
						</a>
					</li>
					<li>
						<a href="https://instagram.com/justdrinksapp" target="_blank" class="item-link external">
							<div class="item-content">
								<div class="item-media">
									<i class="fa-stack">
										<span class="fas fa-square fa-stack-2x brand-color-instagram ios-only"></span>
										<span class="fas fa-circle fa-stack-2x brand-color-instagram md-only"></span>
										<span class="fab fa-instagram fa-stack-1x fa-inverse"></span>
									</i>
								</div>
								<div class="item-inner">
									<div class="item-title">Follow us on Instagram</div>
								</div>
							</div>
						</a>
					</li>
				</ul>
			</div>

			<div class="block-title" data-i18n="help">Help</div>
			<div class="list inset">
				<ul>
					<li>
						<a href="/about/" class="item-link">
							<div class="item-content">
								<div class="item-inner">
									<div class="item-title" data-i18n="about">About</div>
								</div>
							</div>
						</a>
					</li>
					<li>
						<a href="/contact/" class="item-link">
							<div class="item-content">
								<div class="item-inner">
									<div class="item-title" data-i18n="contact-us">Contact Us</div>
								</div>
							</div>
						</a>
					</li>
				</ul>
			</div>

			<div class="block-title" data-i18n="legal">Legal</div>
			<div class="list inset">
				<ul>
					<li>
						<a href="/terms/" class="item-link">
							<div class="item-content">
								<div class="item-inner">
									<div class="item-title" data-i18n="terms-of-use">Terms of Use</div>
								</div>
							</div>
						</a>
					</li>
					<li>
						<a href="/privacy/" class="item-link">
							<div class="item-content">
								<div class="item-inner">
									<div class="item-title" data-i18n="privacy-policy">Privacy Policy</div>
								</div>
							</div>
						</a>
					</li>
					<li>
						<a href="/cookies/" class="item-link">
							<div class="item-content">
								<div class="item-inner">
									<div class="item-title" data-i18n="cookie-policy">Cookie Policy</div>
								</div>
							</div>
						</a>
					</li>
				</ul>
			</div>
</Page>
<script>
  import { f7,Page, Navbar, Block, BlockTitle,List,ListItem } from 'framework7-svelte';
</script>