<App params={ f7params } themeDark={themeDark}>

  <!-- Left panel with cover effect-->
  <Panel left cover>
    <View>
      <Page>
        <Navbar title="Left Panel"/>
        <Block>Left panel content goes here</Block>
      </Page>
    </View>
  </Panel>


  <!-- Right panel with reveal effect
 
    {#if $user}
   <div class="panel panel-right panel-reveal">
    <View>
      <Page noNavbar>  
        <div class="panel-header">
            {#if !$user.photoURL == ''}
                <img src={$user.photoURL} alt="" style="border-radius:50%" width="80px" />
            {:else}
              <div class={'avatar-small avatarsmall' + avatar}></div>
            {/if}
            
            <p>{#if $user.displayName} {$user.displayName}{/if}</p>
        </div>
        <div class="block-title" data-i18n="account">Account</div>
        <div class="list inset">
          <ul>
            <li>
              <a href="/profile/" class="item-link">
                <div class="item-content">
                  <div class="item-media">
                    <i class="fa-stack">
                      <span class="fas fa-square fa-stack-2x text-color-blue ios-only"></span>
                      <span class="fas fa-circle fa-stack-2x text-color-blue md-only"></span>
                      <span class="fas fa-user fa-stack-1x fa-inverse"></span>
                    </i>
                  </div>
                  <div class="item-inner">
                    <div class="item-title" data-i18n="profile">Profile</div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="block-title" data-i18n="app-title">{appName}</div>
			<div class="list inset">
				<ul>
					<li>
						<div class="item-content">
							<div class="item-media">
								<i class="fa-stack">
									<span class="fas fa-square fa-stack-2x text-color-blue ios-only"></span>
									<span class="fas fa-circle fa-stack-2x text-color-blue md-only"></span>
									<span class="fas fa-info fa-stack-1x fa-inverse"></span>
								</i>
							</div>
							<div class="item-inner">
								<div class="item-title" data-i18n="version">Version</div>
								<div class="item-after">1.1.008</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
      <div class="block-title" data-i18n="social">Social</div>
			<div class="list inset">
				<ul>
					<li>
						<a href="https://facebook.com/justdrinksapp" target="_blank" class="item-link external">
							<div class="item-content">
								<div class="item-media">
									<i class="fa-stack">
										<span class="fas fa-square fa-stack-2x brand-color-facebook ios-only"></span>
										<span class="fas fa-circle fa-stack-2x brand-color-facebook md-only"></span>
										<span class="fab fa-facebook-f fa-stack-1x fa-inverse"></span>
									</i>
								</div>
								<div class="item-inner">
									<div class="item-title">Facebook</div>
								</div>
							</div>
						</a>
					</li>
					<li>
						<a href="https://instagram.com/justdrinksapp" target="_blank" class="item-link external">
							<div class="item-content">
								<div class="item-media">
									<i class="fa-stack">
										<span class="fas fa-square fa-stack-2x brand-color-instagram ios-only"></span>
										<span class="fas fa-circle fa-stack-2x brand-color-instagram md-only"></span>
										<span class="fab fa-instagram fa-stack-1x fa-inverse"></span>
									</i>
								</div>
								<div class="item-inner">
									<div class="item-title">Instagram</div>
								</div>
							</div>
						</a>
					</li>
				</ul>
			</div>

			<div class="block-title" data-i18n="help">Help</div>
			<div class="list inset">
				<ul>
					<li>
						<a href="/about/" class="item-link">
							<div class="item-content">
								<div class="item-inner">
									<div class="item-title" data-i18n="about">About</div>
								</div>
							</div>
						</a>
					</li>
					<li>
						<a href="/contact/" class="item-link">
							<div class="item-content">
								<div class="item-inner">
									<div class="item-title" data-i18n="contact-us">Contact Us</div>
								</div>
							</div>
						</a>
					</li>
				</ul>
			</div>

			<div class="block-title" data-i18n="legal">Legal</div>
			<div class="list inset">
				<ul>
					<li>
						<a href="/terms/" class="item-link">
							<div class="item-content">
								<div class="item-inner">
									<div class="item-title" data-i18n="terms-of-use">Terms of Use</div>
								</div>
							</div>
						</a>
					</li>
					<li>
						<a href="/privacy/" class="item-link">
							<div class="item-content">
								<div class="item-inner">
									<div class="item-title" data-i18n="privacy-policy">Privacy Policy</div>
								</div>
							</div>
						</a>
					</li>
					<li>
						<a href="/cookies/" class="item-link">
							<div class="item-content">
								<div class="item-inner">
									<div class="item-title" data-i18n="cookie-policy">Cookie Policy</div>
								</div>
							</div>
						</a>
					</li>
				</ul>
			</div>
      </Page>
    </View>
    </div>
     {/if}
     -->
<!---
      <Link tabLink="#view-dummy" popupOpen=".b-popup" iconIos="f7:chat_bubble_text" iconAurora="f7:chat_bubble_text" iconMd="material:chat" text="Ask Bar Bot" />
-->

    <!-- Views/Tabs container -->
  <Views tabs class="safe-areas" >
    <!-- Tabbar  -->
    {#if $comingSoon == false} 
    <Toolbar tabbar labels bottom class="main-toolbar" noShadow>
      <Link tabLink="#view-home" tabLinkActive iconIos="material:menu_book" iconAurora="material:menu_book" iconMd="material:menu_book" text="Menu" />
      <!--<Link tabLink="#view-explore" iconIos="material:search" iconAurora="material:search" iconMd="material:search" text="Explore" />-->
      <Link tabLink="#view-favorites" iconIos="material:favorite" iconAurora="material:favorite" iconMd="material:favorite" text="Favorites" />

      <Link tabLink="#view-alerts" text="Orders" class="view-alerts-link">
        {#if $venueOrder}
        <NotificationBell color="white" count={$venueOrder.requests.length} pageName="venue" notifColor="red" icon="local_bar"></NotificationBell>
        {:else}
         <NotificationBell color="white" count={0} pageName="venue" notifColor="red" icon="local_bar"></NotificationBell>
        {/if}
      </Link>
      <Link tabLink="#view-more" iconIos="material:more_vert" iconAurora="material:more_vert" iconMd="material:more_vert" text="More" />
      <Link tabLink="#view-ask" text="Ask Sally">
        <img src="https://img.imageboss.me/justdrinks/height/48/hostess-icon.png" alt="" width="24px" />
      </Link>
    </Toolbar>
   {/if}
    <!-- end Toolbar -->

    <!-- Your main view/tab, should have "view-main" class. It also has "tabActive" prop -->
    {#if $comingSoon}
     <View id="view-home" name="main" main tab tabActive url="/coming/" />
    {:else}
     <View id="view-home" name="main" main tab tabActive url="/" />
    {/if}
    <!-- Catalog View -->
    <View id="view-explore" name="exploreview" tab url="/explore/" />
 <View id="view-favorites" name="history" tab url="/favorites/" />
 <View id="view-alerts" name="alerts" tab url="/alerts/" on:tabShow={()=> showAlerts.set(true)} />
 <View id="view-more" name="more" tab url="/more/" />
<View id="view-ask" name="ask" tab url="/ask/" />
  </Views>
  <NetworkCheck on:isOnline={networkOnline}></NetworkCheck>


<Loadable loader={() => import('./dts-shared-components/location/location.svelte')}></Loadable>
<Loadable loader={() => import('./dts-shared-components/firebase/firebase.svelte')} config={firebaseAppConfig} geoFirestore={true} loadScripts={true}></Loadable>
<Loadable loader={() => import('./dts-shared-components/auth/login.svelte')} title="Unlock the full Just Drinks experience!" appUrl={appUrl} userAnonymous={true} required={false}></Loadable>

  <Notification messageKey="BGWpIlpjaWL5hrHpIq9Sb6NQ5UCUDhEFaMsOAoAPXcBYCrXycgxfbmnaIj4kuBss5aGepR_A-sICoddr0mZtrU4" on:onMessage={onNotifMessage} notifMessage="We would like to send you notifications on drink orders when you are away from the app. Would you like to enable notifications now?"></Notification>
</App>
<style>
	.panel-header {
				align-items: center;
				background-image: linear-gradient(-20deg, var(--f7-theme-color) 0%, var(--f7-theme-color-shade) 100%);
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				height: 144px;
				justify-content: center;
				padding: 16px;
				text-align: center;
				width: 100%;
			}
</style>
<script>
 import Loadable from 'svelte-loadable'
  import { onMount,tick } from 'svelte';
  import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
  import {firebaseAppConfig} from '../js/firebase-config' // App firebase info
import Notification from '../components/dts-shared-components/notification/notification.svelte'
import NotificationBell from './dts-shared-components/notification/bell.svelte'
//import FireBase from './dts-shared-components/firebase/firebase.svelte'

import NetworkCheck from './dts-shared-components/network/network.svelte'
//import Login from '../components/dts-shared-components/auth/login.svelte';
import Logout from '../components/dts-shared-components/auth/logout.svelte';
//import Location from '../components/dts-shared-components/location/location'
 import logo from '../static/icons/logo2-96.png'
import {saveProfile,place,places,locInitialized,hideMainToolbar,loadingData,appInitialized,userInfo,comingSoon,showAlerts,venueOrder,darkMode,userDarkMode} from '../js/store'
import {gotPosition,position,requestPermission} from './dts-shared-components/location/store'
import {firestoreInstance,firebaseLoaded,GeoFirestoreLoaded} from './dts-shared-components/firebase/store'
import {notifications, gotNavPermission,messaging,messagingToken} from './dts-shared-components/notification/store'
import {user,loginStatus} from './dts-shared-components/auth/store'
import {updateTableUser} from '../js/core'
import {
    f7,f7ready,App,
    Panel,Views,
    View,Popup,Page,
    Navbar,Toolbar,
    NavRight,Link,Block,
    BlockTitle,LoginScreen,LoginScreenTitle,
    List,ListItem,ListInput,ListButton,BlockFooter,FabBackdrop
  } from 'framework7-svelte';
let avatar="";
let appName="";
let themeDark=false;


if (window.matchMedia && 
    window.matchMedia('(prefers-color-scheme: dark)').matches) {
     themeDark=true;
}

$: if ($userDarkMode){
  if ($darkMode){
    themeDark=true;
  }else{
    themeDark=false;
  }
}


/*
$: if ($messaging){
   if ($gotNavPermission){
      $messaging.requestPermission()
          .then(()=>{
            return $messaging.getToken();
          })
          .then((token)=>{
              messagingToken.set(token)
          })
          .catch(()=>{

          })
   }
}
*/

import {initializeI18n} from '../js/core'

import cocktailIcon from '../assets/icons/cocktail-icon.png'
import beerlIcon from '../assets/icons/beer-icon2.png'

  

  import cordovaApp from '../js/cordova-app';
  import routes from '../js/routes';

  let appUrl="https://test.justdrinks.app/";

   if(window.location.href.indexOf("localhost") > -1) {
     appUrl="http://localhost:5000/";
   }
 
let showBot;

$: {
  if ($saveProfile){
    saveProfile.set(false);
    let ui=$userInfo;
    avatar=ui.defaultAvatar;
     let userRef=$firestoreInstance.collection(`users`);
     userRef.doc($user.uid).set(ui,{merge:true})
  }

  if ($loginStatus){
    //if (!$userInfo)
    initUser();
    
  }



}


function networkOnline(e){
  let d=e.detail;
  f7ready(()=>{
    if (!d.isOnline){
       f7.dialog.alert("No network connection was found. Please check your wifi settings.")
    }else{
      // load firebase component
      console.log("imported firebase component")
    }
  })
 
}

// Any events found from background service?
 if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', function(event) {
          let p=event.data;
          let notifs=$notifications;
          let obj={id: p.data.id,pic: p.data.image,title: p.data.title + "sw",msg: p.data.body,time:new Date()};
          notifs.unshift(obj);
          notifications.set(notifs);
      });
  }




  // Framework7 Parameters
  let f7params = {
    id: 'cloud.dtown.justdrinks', // App bundle ID
    name: 'Just Drinks!', // App name
    
    theme: 'md', // Automatic theme detection
    rtl:false,
    // App root data
    data: function () {
      return {
        logo: 'https://img.imageboss.me/justdrinks/width/300/logo.png',
        gotPlaces: false,
        setup: false,
        // Demo products for Catalog section
        products: [
          {
            id: '1',
            title: 'Apple iPhone 8',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi tempora similique reiciendis, error nesciunt vero, blanditiis pariatur dolor, minima sed sapiente rerum, dolorem corrupti hic modi praesentium unde saepe perspiciatis.'
          },
          {
            id: '2',
            title: 'Apple iPhone 8 Plus',
            description: 'Velit odit autem modi saepe ratione totam minus, aperiam, labore quia provident temporibus quasi est ut aliquid blanditiis beatae suscipit odio vel! Nostrum porro sunt sint eveniet maiores, dolorem itaque!'
          },
          {
            id: '3',
            title: 'Apple iPhone X',
            description: 'Expedita sequi perferendis quod illum pariatur aliquam, alias laboriosam! Vero blanditiis placeat, mollitia necessitatibus reprehenderit. Labore dolores amet quos, accusamus earum asperiores officiis assumenda optio architecto quia neque, quae eum.'
          },
        ]
      };
    },

    // App routes
    routes: routes,

    // Register service worker
    serviceWorker: Device.cordova ? {} : {
      path: '/service-worker.js',
    },
    // Input settings
    input: {
      scrollIntoViewOnFocus: Device.cordova && !Device.electron,
      scrollIntoViewCentered: Device.cordova && !Device.electron,
    },
    // Cordova Statusbar settings
    statusbar: {
      iosOverlaysWebView: true,
      androidOverlaysWebView: false,
    },
  };


  

  
  let rightPanel;
  function InitializePanel(){
    if (!rightPanel)
    rightPanel = f7.panel.create({
      el: '.panel-right',
      on: {
        opened: function () {
          console.log('Panel opened')
        }
      }
    })
  }
 
  function initUser(){
    appInitialized.set(true);

     InitializePanel();
     if (!$userInfo){
       let userRef=$firestoreInstance.collection(`users`);
        userRef.doc($user.uid).get()
        .then((qs)=>{
          if (qs.exists){
            console.log("got user data")
            let ui=qs.data();
            ui.id=$user.uid;
            avatar=ui.defaultAvatar;
            userInfo.set(ui);


          }else{
                let ui={created: new Date(),birthMonth:'',birthYear:'',ageConfirmed:false,over21: false,themeDark: true,defaultAvatar:'9',alc:[]};
                  userRef.doc($user.uid).set(ui)
                  .then(()=>{
                    userInfo.set(ui);
                });

          }
        })
     }else{
       
        
     }
     
  }

  function onNotifMessage(e){
  }

function initializeA2HS() {
  if (!f7.device.ios)
    window.addEventListener('beforeinstallprompt', function(event) {
      event.preventDefault();
      f7.a2hs = event;
      let dialog = f7.dialog.create({
        title: '',
        content: `<div class="block no-margin no-padding text-align-center" style="font-size: 14px;"><img src="${f7.data.logo}" width="100" alt="" /><p><b>Add ${f7.name} to your Home Screen?</b></p><p>Install ${f7.name} on your home screen for quick and easy access when you\'re on the go.</p></div>`,
        verticalButtons: true,
        buttons: [
          {
            text: 'Add to Home Screen',
            bold: true,
            color: 'green',
            onClick: function() {
              f7.a2hs.prompt();
              f7.a2hs.userChoice
                .then(function(choice) {
                if (choice.outcome == 'accepted') {
                  f7.toast.show({
                    text: 'Yaay! Added to Home Screen',
                    position:'bottom',
                    cssClass: 'toast-round bg-color-green',
                    closeTimeout: 3000,
                  });
                }
                else {
                  f7.toast.show({
                    text: 'Oops! Could not add to Home Screen',
                    position:'bottom',
                    cssClass: 'toast-round bg-color-red',
                    closeTimeout: 3000,
                  });
                }
                f7.a2hs = null;
              });
              f7.dialog.close();
            }
          },
          {
            text: 'No, Thanks',
            color: 'gray'
          }
        ]
      });
      setTimeout(function() {
        if (dialog)
          dialog.open();
      }, 60000);
    });
  
}

import {lastScannedVenue} from '../js/store'
import {differenceInMinutes} from 'date-fns'
let activeTableUnsubscribe;
/* Watch current table at app level */
$: if ($appInitialized){
  if ($lastScannedVenue){
    if (!activeTableUnsubscribe)
      activeTableUnsubscribe=$firestoreInstance.collection(`activeTbl`).doc($lastScannedVenue.tid)
          .onSnapshot(async function(doc) {
            console.log("-------------Active tBLe updated")
            if (doc.exists){
              let data=doc.data();
              if (!data.isClosed){
                data.id=doc.id;

                  let users=[];
                    await doc.ref.collection('users').orderBy('created')
                    .get()
                    .then((qs)=>{
                        qs.forEach(function(doc) {
                            let data=doc.data();
                            // doc.data() is never undefined for query doc snapshots
                            users.push({ id: doc.id,name: data.name, pic: data.pic,avatar: data.avatar});
                        });
                    
                        data.users=users;
                    });
                    // If greater, check if user exists
                    let m=differenceInMinutes(new Date(),new Date(data.created.seconds * 1000))
                    let fi=0;
                    if (m > 1)
                      fi=users.findIndex((u)=>{
                        return u.id == $user.uid;
                      })
                    if (fi == -1){
                      // if user doesnt exist
                      lastScannedVenue.set(null);
                      venueOrder.set(null);
                      	f7.views.main.router.navigate({ name: 'home' });
                    }else{
                            let requests=[];
                      await doc.ref.collection('requests')
                      .get()
                      .then((qs)=>{
                          qs.forEach(function(doc) {
                          let data=doc.data();
                          data.id=doc.id;
                              // doc.data() is never undefined for query doc snapshots
                          requests.push(data);
                          });
                      
                          data.requests=requests;
                      });
                      //order=data;
                      venueOrder.set(data);
                    }
                  
              }else{
                  lastScannedVenue.set(null);
                  venueOrder.set(null);
                  	f7.views.main.router.navigate({ name: 'home' });
              }

              //tableInfo=doc.data();
            }else{
                lastScannedVenue.set(null);
              venueOrder.set(null);
              	f7.views.main.router.navigate({ name: 'home' });
            }
      });
  }
}

  onMount(() => {

    f7ready(() => {
      const self=this;
      // Init cordova APIs (see cordova-app.js)
      if (Device.cordova) {
        cordovaApp.init(f7);
      }
      initializeA2HS();
      appName=f7.name;

      // Call F7 APIs here
      // Request location permission
     // if (!$gotPosition)
     
      
      //initializeI18n();
    //Delay a few seconds then hide over
     

      //f7.data.gotPlaces=$locInitialized;


    });
  })
</script>